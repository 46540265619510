import { Col, Container, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import Styles from './Skeleton.module.scss';
import Back from '../../assets/icons/back.svg';
import { StyledSkeleton, StyledSkeletonWrapper } from './Skeleton.styled';
import { dpStyledConstants } from '../../constants/dp-styled-constants';

const SkeletonComponent = (props: any) => {
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);
  return (
    <>
      <StyledSkeletonWrapper>
        <Container>
          <Row>
            <Col xs={2} md={2} className="sp-sel-details__primaryNavigation">
              <div className="d-flex align-self-center align-items-start" />
            </Col>
          </Row>
          <Row>
            <Col lg={2} md={2}>
              <div className={Styles.SkeletonComponent__scrollnav}>
                {dpStyledConstants?.LeftBoxSizings.map((dimensions: any) => (
                  <StyledSkeleton
                    width={dimensions?.width}
                    height={dimensions?.height}
                  />
                ))}
              </div>
            </Col>
            <Col className="" lg={10} md={10}>
              <div className={Styles.SkeletonComponent__rightContent}>
                {dpStyledConstants?.RightBoxSizings.map((dimensions: any) => (
                  <StyledSkeleton
                    width={dimensions?.width}
                    height={dimensions?.height}
                  />
                ))}

                <StyledSkeleton
                  width={dpStyledConstants?.OtherBoxSizings?.width}
                  height={dpStyledConstants?.OtherBoxSizings?.height}
                />
                <div className={Styles.SkeletonComponent__flexBoxes}>
                  <StyledSkeleton width="382px" height="264px" />
                  <StyledSkeleton width="382px" height="264px" />
                </div>
                <StyledSkeleton width="100%" height="24px" />
                <StyledSkeleton width="100%" height="24px" />
                <StyledSkeleton width="100%" height="24px" />
                <StyledSkeleton width="100%" height="24px" />
                <StyledSkeleton width="100%" height="24px" />
              </div>
            </Col>
          </Row>
        </Container>
      </StyledSkeletonWrapper>
    </>
  );
};

export default SkeletonComponent;
