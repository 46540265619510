import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import './phaseDetails.scss';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import Back from '../../assets/icons/back.svg';
import { useActions } from '../../state/hooks/useActions';
import { useTypedSelector } from '../../state/hooks/useTypedSelector';
import useQuery from '../../state/hooks/useQuery';
import navImage from '../../assets/media/nav-chart.svg';
import ModalComponent from '../Modal/ModalComponent';
import Sunburst from '../sunburst/Sunburst';
import {
  NoPhaseDataStyled,
  PhaseDetailColStyled,
  PhaseDetailContainerStyled,
  PhaseDetailRowStyled,
  PhaseDetailStyled,
  PhaseDetailTitleStyled,
  PhaseSelectionStyled,
  MobileSelectionStyled,
  DropDownMobile,
  SubPhaseDropDown,
  DropDownItemStyled,
} from './PhaseDetail.styled';
import { getTheme } from '../../Utilities/sp-strings';
import useDPApi from '../../lib/api';
import SkeletonComponent from '../Skeleton/Skeleton';
import { converter } from '../../Utilities/showDown';
import { isoFormat } from 'd3';

const PhaseDetailsComponent = () => {
  const phaseDetailPage = useParams();
  const { loadPhaseData } = useDPApi();
  const { data } = useTypedSelector((state) => state.phaseDetails);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const query = useQuery();
  let phaseDetailItem: any = {};
  let subPhaseDetailItem: any = {};
  const { toggleModal } = useActions();
  const { masterChartData } = useTypedSelector((state) => state.chartData);
  const isLoading = useTypedSelector((state) => state.setLoading);
  const modalRoot: any = document.getElementById('modal-root');
  const isOpen = useTypedSelector((state) => state.toggleModal);
  const linkRef = useRef() as MutableRefObject<HTMLAnchorElement>;
  const { setTheme } = useActions();
  let PhaseNavs: any = [];
  const currentTheme: any = getTheme.find((category: any) => {
    return category.name === phaseDetailPage.page;
  });
  const theme = {
    background: currentTheme?.palette?.shade2,
    color: currentTheme?.palette?.hue,
    mediaItemsBackdrop: currentTheme?.palette?.shade,
    borderColor: currentTheme?.palette?.shade,
    highlight: currentTheme?.palette?.primary,
    active: currentTheme?.palette?.hue,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadPhaseData(phaseDetailPage.page);
  }, [loadPhaseData, phaseDetailPage.page]);

  useEffect(() => {
    if (theme?.borderColor) {
      setTheme({ background: theme?.borderColor, highlight: theme?.color });
    }
  }, [theme]);

  const handleBack = () => {
    navigate(`/${phaseDetailPage.page}`);
  };

  const handleSunburstModal = () => {
    toggleModal(true);
  };
  const SunBurstModal = () => {
    return (
      <ModalComponent>
        {masterChartData && (
          <Sunburst
            masterChartData={masterChartData}
            showContent={!isOpen}
            stopAnimation={true}
          />
        )}
      </ModalComponent>
    );
  };
  PhaseNavs = data?.phases?.phaseTypes?.map((phase: any, index: number) => {
    const navIsActive =
      query.get('id') === phase.tablInternalId ||
      (!query.get('id') && index == 0)
        ? query.get('subPhase')
          ? 'subPhaseActive'
          : 'active'
        : '';
    return (
      <>
        <PhaseSelectionStyled
          about={navIsActive}
          ref={linkRef}
          className="phaseSelection__navigation_links d-none d-md-block"
          onClick={() =>
            navigate(index === 0 ? '' : `?id=${phase?.tablInternalId}`)
          }
        >
          <h2>{phase?.tabTitle}</h2>
        </PhaseSelectionStyled>
        <MobileSelectionStyled
          className=" phaseSelection__navigation_links--mobile d-md-none"
          about={navIsActive}
          ref={linkRef}
          onClick={() =>
            navigate(index === 0 ? '' : `?id=${phase?.tablInternalId}`)
          }
        >
          <h6>{phase?.tabTitle}</h6>
        </MobileSelectionStyled>
      </>
    );
  });

  if (data?.phases?.phaseTypes?.length) {
    phaseDetailItem = query.get('id')
      ? data?.phases?.phaseTypes?.find((phase: any) => {
          return phase.tablInternalId === query.get('id');
        })
      : data?.phases?.phaseTypes[0];

    if (phaseDetailItem?.subphases && query.get('subPhase')) {
      subPhaseDetailItem = phaseDetailItem?.subphases?.subPhasetypes?.find(
        (subPhase: any) => {
          return subPhase?.tabInternalId === query.get('subPhase');
        }
      );
    }
  }

  const phaseItem = (query.get('subPhase')
    ? subPhaseDetailItem
    : phaseDetailItem
  )?.tabContent?.map((content: any) => (
    <PhaseDetailRowStyled className="phaseSelection__row">
      <PhaseDetailColStyled md={2} className="phaseSelection__row__cellOne">
        <div>
          <label className="bold">{content?.contentHeader}</label>
        </div>
      </PhaseDetailColStyled>
      <Col md={8} className="phaseSelection__row__cellTwo">
        <div
          dangerouslySetInnerHTML={{
            __html: converter?.makeHtml(content?.contentBody?.md),
          }}
        />
      </Col>
    </PhaseDetailRowStyled>
  ));

  return (
    <ThemeProvider theme={theme}>
      <PhaseDetailStyled className="phaseSelection">
        {isLoading ? <SkeletonComponent /> : null}
        {isOpen && ReactDOM.createPortal(<SunBurstModal />, modalRoot)}
        <Container className="animated animatedFadeInUp fadeInUp phaseSelection__wrapContainer">
          {isLoading ? <SkeletonComponent /> : null}
          <Row>
            <div className="d-flex px-0 phaseSelection__primaryNavigation">
              <div
                onClick={handleBack}
                className="d-flex align-self-center align-items-start phaseSelection__back"
              >
                <img src={Back} alt="back-button" />
                <p>Back</p>
              </div>
            </div>
          </Row>
          <Row>
            <Col md={2}>
              <div>
                <img
                  onClick={handleSunburstModal}
                  src={navImage}
                  alt="Navigation Image"
                  className="phaseSelection__nav-chart"
                />
              </div>
            </Col>
            <Col md={10}>
              <p className="title-eyebrow">{data?.title}</p>
              <h1>
                The various{' '}
                <PhaseDetailTitleStyled className="span-title">
                  {data?.title}
                </PhaseDetailTitleStyled>
                , how we do it, what is in store and the final outcome in
                detail.
              </h1>
              {!data?.phases?.phaseTypes?.length && (
                <NoPhaseDataStyled>
                  <h4 className="animated animatedFadeInUp fadeInUp">
                    {' '}
                    Oops! No Data Found :/
                  </h4>
                </NoPhaseDataStyled>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {PhaseNavs?.length && (
                <>
                  <Row className="phaseSelection__navigation">
                    <Col
                      className="d-flex justify-content-start align-items-center"
                      md={2}
                    >
                      <label className="bold">{data?.phases?.title}</label>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <div className="d-flex flex-wrap">{PhaseNavs}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col></Col>
                  </Row>
                </>
              )}
              {phaseDetailItem?.subphases?.subPhasetypes?.length ? (
                <>
                  <Row className="phaseSelection__navigation d-none d-md-flex">
                    <Col
                      className="d-none d-md-flex justify-content-start align-items-center"
                      md={2}
                    >
                      <label className="bold">
                        {phaseDetailItem?.subphases?.title}
                      </label>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <div className="d-flex justify-content-evenly">
                        {phaseDetailItem?.subphases?.subPhasetypes?.map(
                          (subPhase: any) => (
                            <PhaseSelectionStyled
                              key={subPhase?.tabInternalId}
                              about={
                                query.get('subPhase') ===
                                subPhase?.tabInternalId
                                  ? 'active'
                                  : ''
                              }
                              ref={linkRef}
                              className="phaseSelection__navigation_links d-none d-md-block"
                              onClick={() =>
                                navigate(
                                  `?id=${phaseDetailItem?.tablInternalId}&subPhase=${subPhase?.tabInternalId}`
                                )
                              }
                            >
                              <h2>{subPhase?.tabTitle}</h2>
                            </PhaseSelectionStyled>
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex d-md-none justify-content-between align-items-center pb-4">
                    <div>
                      <label className="bold">About</label>
                    </div>
                    <div>
                      <Dropdown>
                        <SubPhaseDropDown
                          variant="success"
                          id="dropdown-basic"
                          value="here"
                          className="phaseSelection__dropDown d-flex justify-content-between align-items-center"
                        >
                          {!subPhaseDetailItem?.tabTitle
                            ? 'Sub Phase'
                            : subPhaseDetailItem?.tabTitle}
                        </SubPhaseDropDown>
                        <DropDownMobile className="phaseSelection__dropDown--menu">
                          {phaseDetailItem?.subphases?.subPhasetypes?.map(
                            (subPhase: any) => (
                              <DropDownItemStyled
                                isselected={
                                  query.get('subPhase') ===
                                  subPhase?.tabInternalId
                                }
                                onClick={() => {
                                  navigate(
                                    `?id=${phaseDetailItem?.tablInternalId}&subPhase=${subPhase?.tabInternalId}`
                                  );
                                }}
                              >
                                {subPhase?.tabTitle}
                              </DropDownItemStyled>
                            )
                          )}
                        </DropDownMobile>
                      </Dropdown>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          {phaseItem && (
            <PhaseDetailContainerStyled className="phaseSelection__container">
              <Col>{phaseItem}</Col>
            </PhaseDetailContainerStyled>
          )}
        </Container>
      </PhaseDetailStyled>
    </ThemeProvider>
  );
};

export default PhaseDetailsComponent;
