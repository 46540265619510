import { Dispatch } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../action-types';

export const loadAction = (data: any) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_LOADER,
      payload: data,
    });
  };
};
