import { Col, Row } from 'react-bootstrap';
import { MediaItemsDetailsSchema } from '../../../models/play-details';

import './solution-links.scss';
import { SolutionLink } from './solution-links.styled';

interface SolutionLinksPropTypes {
  solutions: MediaItemsDetailsSchema[];
}

const SolutionLinks: React.FC<SolutionLinksPropTypes> = ({
  solutions,
}: any) => {
  return (
    <Row className="sp-solution">
      {solutions?.map((item: any) => (
        <Col xs={12} md={6} lg={4} key={item?.label}>
          <SolutionLink
            href={item?.link}
            target="_blank"
            className="sp-solution__item"
          >
            {item?.label}
          </SolutionLink>
        </Col>
      ))}
    </Row>
  );
};

export default SolutionLinks;
