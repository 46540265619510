import { Col, Container, Row } from 'react-bootstrap';
import deloitte from '../../assets/media/faq-banner.jpg';
import './Banner.scss';

const BannerComponent = (props: any) => {
  return (
    <div className="banner__hero">
      <Container>
        <Row className="d-flex justify-content-center align-items-baseline">
          <Col md={3}>
            <div>
              <h1>{props?.title}</h1>
            </div>
          </Col>
          <Col sm={12} lg={9} md={2}>
            <div className="banner__foreGroundImage">
              <img src={deloitte} alt="rectangle" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BannerComponent;
