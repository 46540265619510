import { MutableRefObject, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../assets/icons/Search.svg';
import useDPApi from '../../lib/api';

const DpSearch = (props: any) => {
  const searchQuery = useRef() as MutableRefObject<HTMLInputElement>;
  const { loadSearchQuery } = useDPApi();
  const navigate = useNavigate();
  const handlePage = (e: any) => {
    e.preventDefault();
    // loadSearchQuery(searchQuery?.current?.value);
    if (searchQuery?.current?.value.trim()) {
      navigate(`/search/${searchQuery?.current?.value}`);
    }
    searchQuery.current.value = '';
  };
  return (
    <>
      <Form
        className="search-box search-form form-inline"
        onSubmit={handlePage}
      >
        <div className="input-group">
          <img className="search-box__icon" src={SearchIcon} alt="download" />
          <Form.Control
            type="search"
            ref={searchQuery}
            placeholder="Search for plays, case studies, quals and more.."
            className="me-2 sf-header__search"
            aria-label="Search"
          />
        </div>
      </Form>
    </>
  );
};
export default DpSearch;
