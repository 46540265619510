import { useState } from 'react';
import styles from './checkbox.module.scss';

const Checkbox = (props: any) => {
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckBox = () => {
    setIsChecked((check) => {
      return (check = !check);
    });

    props.onCheck(isChecked);
  };

  return (
    <>
      <label className={`${styles.container} tabs-bold select`}>
        {props.title}
        <input onClick={handleCheckBox} type="checkbox" />
        <span className={styles.checkmark} />
      </label>
    </>
  );
};

export default Checkbox;
