import './FloatingButton.scss';

const FloatingButton = () => {
  return (
    <a className="floatingButton" href="mailto:ustheplays@deloitte.com">
      Talk to us
    </a>
  );
};
export default FloatingButton;
