import { ActionType } from '../action-types';
import { Action } from '../actions';

const initialState = {
  selectedFiles: [],
};

const fileDownloadReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.FILE_SELECTION:
      let FilesArr: any = state.selectedFiles;

      if (!action.payload) {
        FilesArr = [];
      } else if (!action.payload.checked) {
        const updatedFilesArray = FilesArr.filter((file: any) => {
          return file.id !== action.payload.id;
        });

        FilesArr = updatedFilesArray;
      } else {
        FilesArr.push(action.payload);
      }

      return {
        selectedFiles: FilesArr,
      };

    default:
      return state;
  }
};

export default fileDownloadReducer;
