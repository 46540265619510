import { Dispatch } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../action-types';

export const setTheme = (color: any) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_THEME,
      payload: color,
    });
  };
};
