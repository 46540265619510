import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Nav, NavDropdown, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Mark from 'mark.js';
import { searchResults } from '../../models/play-details';
import { useActions } from '../../state/hooks/useActions';
import { useTypedSelector } from '../../state/hooks/useTypedSelector';
import FileDownloadComponent from '../FileDownlaodComponent/FileDownloadComponent';
import styles from './Search-results.module.scss';
import SearchResultCard from './Search-result-card/Search-result-card';
import downloadIcon from '../../assets/icons/Download.svg';
import Pagination from '../Pagination/Pagination';
import JSZipUtils from '../../Utilities/JSZipUtils';
import noResultsIcon from '../../assets/icons/Search_pge.svg';
import { spStringConstants } from '../../Utilities/sp-strings';
import useDPApi from '../../lib/api';
import { UserAssetAPI } from '@deloitte/blueprint-sdk-asset/.';
import BackNavigation from '../backNavigationButton/BackNavigation';

const SearchResults = () => {
  const { loadSearchResults } = useActions();
  const { loadSearchQuery } = useDPApi();
  const [totalPagesData, setTotalPagesData] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [currDocPage, setCurrDocPage] = useState(1);

  const searchResultsData: any = useTypedSelector(
    (state) => state.searchResults
  );
  const { userAssetApi } = useTypedSelector<any>((state) => state.setUserAuth);
  const data: any = useTypedSelector((state) => state.setSearchQuery);
  const selectedFilesData = useTypedSelector(
    (state) => state.fileDownloadSelection
  );
  const { selectFiles } = useActions();
  const query: any = useParams();
  const { setTheme } = useActions();
  const [showTabSelected, setTabSelected] = useState('pages');
  useEffect(() => {
    loadSearchQuery(query.results.toLowerCase());
    loadSearchResults(query.results.toLowerCase());
    setTheme({
      background: spStringConstants?.neutralFooterTheme,
      highlight: '#ffff',
    });
  }, [query.results]);

  const handleTabs = (e: any) => {
    e.preventDefault();
    if (e.currentTarget.id === 'pages') {
      setTabSelected('pages');
      selectFiles(null);
    } else {
      setTabSelected('documents');
    }
  };

  const handleIncrement = () => {
    if (showTabSelected === 'pages') {
      const newPage = currPage + 1;
      loadSearchQuery(query.results?.toLowerCase(), newPage);
      setCurrPage(newPage);
    } else {
      const newPage = currDocPage + 1;
      setCurrDocPage(newPage);
      loadSearchQuery(query.results?.toLowerCase(), newPage);
      setCurrPage(newPage);
    }
  };

  const handleDecrement = () => {
    if (showTabSelected === 'pages') {
      const newPage = currPage - 1;
      loadSearchQuery(query.results?.toLowerCase(), newPage);
      setCurrPage(newPage);
    } else {
      const newPage = currDocPage - 1;
      setCurrDocPage(newPage);
      loadSearchQuery(query.results?.toLowerCase(), newPage);
      setCurrPage(newPage);
    }
  };

  const handleSelectedFile = (fileData: any) => {
    selectFiles(fileData);
  };

  const searchResultsFeed = data?.hits?.map((srchFeed: any, index: any) => {
    if (!srchFeed?.contentType) {
      return (
        <SearchResultCard
          data={srchFeed}
          id={srchFeed?.phaseid}
          type={srchFeed?.categoryName}
          key={index}
          title={srchFeed?.title}
          description={srchFeed?._formatted?.summary?.description}
          date={srchFeed?.updatedAt}
        />
      );
    }
  });

  const filesCount = data?.hits?.filter((file: any) => {
    return file?.contentType;
  });

  const pageCount = data?.hits?.filter((file: any) => {
    return !file?.contentType;
  });

  const handleDownload = async (asset: any, fileName: any) => {
    const signedURLRes = await userAssetApi?.getAssetSignedUrl(asset);
    const url = signedURLRes?.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
  };

  const downloadFilesData = data?.hits?.map((fileData: any, index: any) => {
    if (fileData?.contentType) {
      return (
        <FileDownloadComponent
          sendSelectedFile={handleSelectedFile}
          fileData={{ ...fileData, id: index }}
          handleDownload={handleDownload}
        />
      );
    } else {
      return;
    }
  });
  const handleDownloadAll = () => {
    const selectAllFileData = searchResultsData?.downloadFilesData?.map(
      (file: any) => {
        return { src: file.src, name: file.title };
      }
    );

    const onSelectFileData = selectedFilesData.selectedFiles.map(
      (file: any) => {
        return {
          src: file.src,
          name: file.title,
        };
      }
    );

    const fileData = selectedFilesData.selectedFiles.length
      ? onSelectFileData
      : selectAllFileData;

    let count = 0;
    const zip = new JSZip();
    fileData.forEach((file: any) => {
      JSZipUtils.getBinaryContent(file.src, (err: any, data: any) => {
        if (err) {
          throw err;
        }
        zip.file(file.name, data, { binary: true });
        count++;
        if (count === fileData.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, 'salesPlay.zip');
          });
        }
      });
    });
  };

  const highlight = () => {
    const context: any = document.querySelectorAll('.select');
    context.forEach((element: any) => {
      const obj = new Mark(element);
      obj.mark(query.results);
    });
  };

  const unMark = () => {
    const context: any = document.querySelectorAll('.select');
    context.forEach((element: any) => {
      const obj = new Mark(element);
      obj.unmark();
    });
  };

  useEffect(() => {
    highlight();
    return () => {
      unMark();
    };
  }, [query.results, showTabSelected, data]);

  return (
    <div className={styles.searchResults}>
      <Container
        className={`${styles.searchResults__container} animated animatedFadeInUp fadeInUp`}
      >
        <div className={styles.searchResults__backNavigation}>
          <BackNavigation />
        </div>
        <div className={styles.allResults}>
          <div className={styles.allResults__title}>
            <h4>
              Showing all results for{' '}
              <span className="bold">{query.results}</span>
            </h4>
          </div>

          <div className={styles.searchResults__panel}>
            <div
              className={
                `d-flex flex-wrap align-items-center flex-grow-1 ` +
                styles.searchResults__tabGrp
              }
            >
              <div className={styles.searchResults__panel__tabOne}>
                <a
                  href="#"
                  onClick={handleTabs}
                  className={
                    showTabSelected === 'pages'
                      ? `${styles.selectedTab} ${styles.tabs}`
                      : ` ${styles.tabs}`
                  }
                  id="pages"
                  key="pages"
                >
                  <label className="regular">
                    Pages
                    <span>{` (${
                      pageCount?.length ? pageCount?.length : 0
                    })`}</span>
                  </label>
                </a>
              </div>
              <div className={styles.searchResults__panel__tabTwo}>
                <a
                  onClick={handleTabs}
                  href="#"
                  className={
                    showTabSelected === 'documents'
                      ? `${styles.selectedTab} ${styles.tabs}`
                      : ` ${styles.tabs}`
                  }
                  id="documents"
                  key="documents"
                >
                  <label className="regular">
                    Documents
                    <span>{` (${
                      filesCount?.length ? filesCount?.length : 0
                    })`}</span>
                  </label>
                </a>
              </div>
            </div>
            <div className={styles.searchResults__actionPanel}>
              <div className={styles.searchResults__panel__tabFour}>
                <div className={styles.allResults__section__panel__actionPanel}>
                  <div className={`${styles.searchResults__paginationGrp}`}>
                    {showTabSelected === 'pages' ? (
                      <Pagination
                        data={data}
                        query={query?.results?.toLowerCase()}
                        handleIncrement={handleIncrement}
                        handleDecrement={handleDecrement}
                        totalPages={data?.totalPages}
                        currPage={pageCount?.length == 0 ? 0 : currPage}
                      />
                    ) : (
                      <Pagination
                        page={'documents'}
                        handleIncrement={handleIncrement}
                        handleDecrement={handleDecrement}
                        totalPages={data?.totalPages}
                        currPage={filesCount?.length == 0 ? 0 : currDocPage}
                      />
                    )}
                  </div>
                </div>
                {/* {showTabSelected === 'documents' && (
                  <div
                    className={
                      styles.allResults__section__panel_downloadBtn__wrapper
                    }
                    onClick={handleDownloadAll}
                  >
                    <a
                      className={styles.allResults__section__panel_downloadBtn}
                    >
                      <label className="bold">
                        {selectedFilesData.selectedFiles.length
                          ? 'Download Selected'
                          : 'Download All'}
                        <span>
                          <img
                            className={styles.allResults__section__downloadIcon}
                            src={downloadIcon}
                            alt="svg"
                          />
                        </span>
                      </label>
                    </a>
                  </div>
                )} */}
              </div>
              {/* <div className={styles.searchResults__panel__tabThree}>
                {showTabSelected === 'documents' && (
                  <div onClick={handleDownloadAll}>
                    <a
                      className={styles.allResults__section__panel_downloadBtn}
                    >
                      <label className="bold">
                        {selectedFilesData.selectedFiles.length
                          ? 'Download Selected'
                          : 'Download All'}
                        <span>
                          <img
                            className={styles.allResults__section__downloadIcon}
                            src={downloadIcon}
                            alt="svg"
                          />
                        </span>
                      </label>
                    </a>
                  </div>
                )}
              </div> */}
            </div>
          </div>
          <Row>
            <Col>
              <div className={styles.searchResults__content}>
                {showTabSelected === 'pages'
                  ? searchResultsFeed
                  : downloadFilesData}
              </div>
            </Col>
          </Row>
          {!data?.hits.length && (
            <div
              className={
                styles.searchResults__noResults +
                ` d-flex align-items-center justify-content-center  `
              }
            >
              <div
                className={
                  styles.searchResults__noResults__container +
                  ` d-flex align-items-center justify-content-center flex-column`
                }
              >
                <img src={noResultsIcon} alt="svg" />
                <div className={styles.searchResults__noResults__description}>
                  <p className="bold text-center mt-2">No results found</p>
                  <label className="regular text-center">
                    We couldn’t find what you searched for. Try searching again
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default SearchResults;
