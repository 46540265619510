import { ActionType } from '../action-types';
import { Action } from '../actions';

const initialState = {
  searchResultsCategories: [],
  searchResultsResponse: [],
  downloadFilesData: [],
};

const searchResultsReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.LOAD_SEARCH_RESULTS_SUCCESS:
      return {
        searchResultsCategories: action.payload.searchResultsCategories,
        searchResultsResponse: action.payload.searchResultsResponse,
        downloadFilesData: action.payload.downloadFilesData,
      };

    default:
      return state;
  }
};

export default searchResultsReducer;
