import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import font from '../assets/fonts/Prata-Regular.ttf';

Font.register({
  family: 'Prata',
  src: font,
});

export const styles = StyleSheet.create({
  page: {
    paddingBottom: '30px',
    paddingTop: '30px',
  },

  header: {
    backgroundColor: '#000',
    padding: '10px',
    color: '#FFF',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    transform: 'translateY(-30%)',
  },
  headerLogo: {
    width: '35.78px',
    height: '32.51px',
    transform: 'scale(0.8)',
  },

  headerBg: {
    width: '100vw',
    height: '200px',
    position: 'absolute',
    left: '0',
    top: '-100%',
    zIndex: '-1',
  },

  headerText: {
    fontSize: '14px',
  },

  list: {
    display: 'flex',
    flexDirection: 'row',
    margin: '14px 0',
    paddingRight: '30px',
  },

  playTitle: {
    fontSize: '32px',
    fontFamily: 'Prata',
    marginBottom: '60px',
    padding: '0px 30px',
    color: '#FFF',
    transform: 'translateX(-20%)',
  },
  section: {
    marginTop: '20px',
    padding: '0px 30px',
  },
  sectionTitle: {
    fontFamily: 'Prata',
    fontSize: '24px',
    marginBottom: '7px',
  },
  footerPage: {
    padding: '5px 30px 0 30px',
    fontSize: '10px',
    position: 'absolute',
    bottom: '10px',
    left: '0',
    color: '#252525',
  },
  sectionDescription: {
    fontSize: '14px',
    lineHeight: '1.5px',
  },

  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  sunBurst: {
    width: '90px',
    height: '90px',
    transform: 'translateY(-30%) scale(0.9)',
  },
  sectionSubTitle: {
    fontSize: '18px',
    fontWeight: 'semibold',
    margin: '7px 0px',
  },
});

export const stylesheet = {
  h5: {
    fontSize: '20px',
  },
  p: {
    fontSize: '16px',
  },
  ul: {
    padding: '0px',
    marginRight: '30px',
  },
  li: {
    fontSize: '16px',
    padding: '0px',
    marginBottom: '10px',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  td: {
    border: '1px solid #dddddd',
    padding: '8px',
  },
  th: {
    border: '1px solid #dddddd',
    padding: '8px',
  },
  tr: {
    '&:nth-child(even)': {
      backgroundColor: '#dddddd',
    },
  },
};
