import { APIConfig } from '@deloitte/blueprint-sdk-core';

const config: APIConfig = {
  apiRoot:
    process.env.REACT_APP_BLUEPRINT_URL ||
    'https://staging.salesportal.sandbox.ddapps.xyz',
  debug: true,
};

export default config;
