import './breadcrumb.scss';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { useTypedSelector } from '../../state/hooks/useTypedSelector';

interface BreadcrumbProps {
  routes: any;
}

const BreadcrumbComponent: React.FC<BreadcrumbProps> = ({ routes }) => {
  return (
    <Breadcrumb className="phaseSelection__breadcrumb">
      {routes.map(
        (route: any) =>
          route.label && (
            <BreadcrumbItem>
              <label>{route.label}</label>
            </BreadcrumbItem>
          )
      )}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
