import axios from 'axios';
import { Dispatch } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../action-types';
import { PlayDetails, searchResults } from '../../models/play-details';

export const toggleModal = (flag: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.TOGGLE_MODAL,
      payload: flag,
    });
  };
};
