import ChartData from '../../models/chart-data';
import { ActionType } from '../action-types';
import { Action } from '../actions';

interface DataState {
  loading: boolean;
  error: string | null;
  masterChartData: ChartData | null;
}

const initialState: DataState = {
  loading: false,
  error: null,
  masterChartData: null,
};

const chartDataReducer = (
  state: DataState = initialState,
  action: Action
): DataState => {
  switch (action.type) {
    case ActionType.LOAD_CHART_DATA:
      return { loading: true, error: null, masterChartData: null };
    case ActionType.LOAD_CHART_DATA_SUCCESS:
      return {
        loading: false,
        error: null,
        masterChartData: action.payload,
      };
    case ActionType.LOAD_CHART_DATA_ERROR:
      return {
        loading: false,
        error: action.payload,
        masterChartData: null,
      };
    default:
      return state;
  }
};

export default chartDataReducer;
