import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ThemeProvider } from 'styled-components';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import {
  ListItemSchema,
  ListTypes,
  MediaItems,
  MediaItemsDetailsSchema,
  MediaItemsType,
} from '../../models/play-details';
import { useActions } from '../../state/hooks/useActions';
import { useTypedSelector } from '../../state/hooks/useTypedSelector';
import LinkComponent from '../Link/link-component';
import SalesCarousel from '../media-items/carousel/carousel';
import DownloadsSection from '../media-items/downloads/downloads';
import SalesVideo from '../media-items/sales-video/sales-video';
import SolutionLinks from '../media-items/solution-pills/solution-links';
import ModalComponent from '../Modal/ModalComponent';
import ScrollSpyNav from '../scrollspy-nav/scrollspy-nav';
import './selection-details.scss';
import Sunburst from '../sunburst/Sunburst';
import Back from '../../assets/icons/back.svg';
import {
  DpSections,
  NoDataStyled,
  SelectionDetailsStyle,
  TitleLink,
  VisitMore,
} from './selection-details.styled';
import PDFDownloader from '../../Utilities/PDFDownload';
import SkeletonComponent from '../Skeleton/Skeleton';
import { getTheme } from '../../Utilities/sp-strings';
import AccordionComponent from '../Accordion/Accordion';
import { converter } from '../../Utilities/showDown';
import convertMarkdownToText from '../../Utilities/marked';

const SelectionDetails: React.FC = () => {
  const { loadPlayDetails } = useActions();
  const { setTheme } = useActions();
  const currentPage = useParams();
  const { data } = useTypedSelector((state) => state.playDetails);
  const [chartData, setChartData] = useState<any>();
  const { masterChartData } = useTypedSelector((state) => state.chartData);
  const navigate = useNavigate();
  const modalRoot: any = document.getElementById('modal-root');
  const isOpen = useTypedSelector((state) => state.toggleModal);
  const [datax, setData] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  let modifiedData: any = null;
  const noPreview: MediaItemsDetailsSchema[] = [
    {
      src: '',
      title: 'No Preview Available',
    },
  ];
  useEffect(() => {
    setChartData(masterChartData);
  }, [masterChartData]);
  const goToPhaseDetails = () => {
    navigate('phases');
  };

  const currentTheme: any = getTheme.find((category: any) => {
    return category.name === currentPage.page;
  });
  const theme = {
    background: currentTheme?.palette?.shade2,
    color: currentTheme?.palette?.hue,
    mediaItemsBackdrop: currentTheme?.palette?.shade,
    highlight: currentTheme?.palette?.primary,
  };

  if (data) {
    modifiedData = data?.find((playCtg: any) => {
      return playCtg?.title === currentPage.page;
    });
  }

  useEffect(() => {
    if (theme?.mediaItemsBackdrop) {
      setTheme({
        background: theme?.mediaItemsBackdrop,
        highlight: currentTheme?.palette?.hue,
      });
    }
    window.scrollTo(0, 0);
  }, [currentPage, theme]);

  const SunBurstModal = () => {
    return (
      <ModalComponent>
        {chartData && (
          <Sunburst
            masterChartData={chartData}
            showContent={!isOpen}
            stopAnimation={true}
          />
        )}
      </ModalComponent>
    );
  };

  const renderScrollSpy = (modifiedData: any) => {
    const parsedIndex = Object?.values(modifiedData)?.map((item: any) => ({
      label: item?.title,
      id: item?.id,
    }));

    return <ScrollSpyNav sectionDetails={parsedIndex} />;
  };

  return (
    <ThemeProvider theme={theme}>
      {modifiedData ? (
        <SelectionDetailsStyle className="sp-sel-details">
          {isOpen && ReactDOM.createPortal(<SunBurstModal />, modalRoot)}
          <Container>
            <Row>
              <Col xs={7} md={10} className="sp-sel-details__primaryNavigation">
                <div
                  className="d-flex align-self-center align-items-start"
                  onClick={() => navigate('/')}
                >
                  <img src={Back} alt="back-button" />
                  <p className="sp-sel-details__back">Back</p>
                </div>
              </Col>
              <Col className="pt-5">
                <PDFDownloadLink
                  document={<PDFDownloader data={modifiedData} />}
                  fileName={modifiedData?.title}
                >
                  {modifiedData?.summary?.title && (
                    <button className="k-pdf-export sp-sel-details__exportButton animated animatedFadeInUp fadeInUp">
                      Export page to PDF
                    </button>
                  )}
                </PDFDownloadLink>
              </Col>
            </Row>
            <Row>
              <Col
                lg={2}
                md={2}
                className="d-md-none d-lg-flex animated animatedFadeInUp fadeInUp"
              >
                {renderScrollSpy(modifiedData)}
              </Col>
              <Col
                className="animated animatedFadeInUp fadeInUp"
                lg={10}
                md={10}
              >
                <div>
                  <div className="d-flex justify-content-between">
                    <div className="k-pdf-export sp-sel-details__info">
                      <h1>{modifiedData?.title}</h1>
                      <p className="sub-heading">
                        {modifiedData?.description?.md}
                      </p>

                      {!modifiedData?.summary?.title && (
                        <NoDataStyled>
                          <h4 className="animated animatedFadeInUp fadeInUp">
                            {' '}
                            Oops! No Data Found :/
                          </h4>
                        </NoDataStyled>
                      )}
                    </div>
                  </div>
                  <div className="test">
                    {Object?.values(modifiedData)?.map(
                      (section: any, index: number) =>
                        section?.title && (
                          <div
                            key={section?.id}
                            className={`sp-sel-details__sections section-${
                              index + 1
                            }`}
                          >
                            <DpSections>
                              <div
                                className={
                                  `${
                                    section?.id === 'phasesInPlay'
                                      ? 'section__phasesInPlay'
                                      : ''
                                  } ${
                                    section?.id === 'successStories'
                                      ? 'section__successStory'
                                      : ''
                                  } ` + ' d-md-block d-none'
                                }
                              >
                                {section?.title && (
                                  <h2 key={`${section?.id}h3`} id={section?.id}>
                                    {section?.title}
                                  </h2>
                                )}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: converter?.makeHtml(
                                      section?.description?.md
                                    ),
                                  }}
                                ></div>
                                {section?.title === 'Summary' && (
                                  <VisitMore
                                    id="goToPhases"
                                    onClick={goToPhaseDetails}
                                  >
                                    <h5 className="visitMore">Visit Phases</h5>
                                  </VisitMore>
                                )}
                              </div>
                              {section?.id !== 'quickDownloads' &&
                                section?.id !== 'qualsFromProjects' &&
                                section?.id !== 'references' && (
                                  <div className="d-md-none">
                                    <AccordionComponent title={section?.title}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: converter?.makeHtml(
                                            section?.description?.md
                                          ),
                                        }}
                                      ></div>
                                      {section?.title === 'Summary' && (
                                        <VisitMore
                                          id="goToPhases"
                                          onClick={goToPhaseDetails}
                                        >
                                          <h5 className="visitMore">
                                            Visit Phases
                                          </h5>
                                        </VisitMore>
                                      )}
                                    </AccordionComponent>
                                  </div>
                                )}
                              <Row>
                                <Col
                                  className="sp-selection-details__mediaWrapper d-none d-md-block"
                                  md={
                                    section?.mediaType === 'Downloads' ? 9 : 12
                                  }
                                >
                                  {section?.mediaType === 'Downloads' && (
                                    <DownloadsSection
                                      downloads={section?.quickDownloadsItems}
                                    />
                                  )}
                                </Col>
                                <Col className="d-md-none">
                                  {section?.mediaType === 'Downloads' && (
                                    <AccordionComponent title={section?.title}>
                                      <DownloadsSection
                                        downloads={section?.quickDownloadsItems}
                                      />
                                    </AccordionComponent>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col className="sp-selection-details__mediaWrapper d-none d-md-block">
                                  {section?.qualCategories?.map(
                                    (qual: any, index: number) => (
                                      <>
                                        <h5 className="sp-selection-details__mediaTitle">
                                          {qual?.qualCategoryName}
                                        </h5>
                                        <SolutionLinks
                                          key={
                                            MediaItemsType.SolutionLinks + index
                                          }
                                          solutions={qual?.quals}
                                        />
                                      </>
                                    )
                                  )}
                                </Col>
                                <Col className="d-md-none">
                                  {section?.id === 'qualsFromProjects' && (
                                    <AccordionComponent title={section?.title}>
                                      {section?.qualCategories?.map(
                                        (qual: any, index: number) => (
                                          <>
                                            <h5 className="sp-selection-details__mediaTitle">
                                              {qual?.qualCategoryName}
                                            </h5>
                                            <SolutionLinks
                                              key={
                                                MediaItemsType.SolutionLinks +
                                                index
                                              }
                                              solutions={qual?.quals}
                                            />
                                          </>
                                        )
                                      )}
                                    </AccordionComponent>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col className="sp-selection-details__mediaWrapper d-md-block d-none">
                                  <>
                                    <SolutionLinks
                                      key={MediaItemsType.SolutionLinks + index}
                                      solutions={section?.referenceList}
                                    />
                                  </>
                                </Col>
                                <Col className="d-md-none">
                                  {section?.id === 'references' && (
                                    <AccordionComponent title={section?.title}>
                                      <SolutionLinks
                                        key={
                                          MediaItemsType.SolutionLinks + index
                                        }
                                        solutions={section?.referenceList}
                                      />
                                    </AccordionComponent>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col className="d-md-block d-none">
                                  {section?.contactList?.map(
                                    (contactCtg: any) => (
                                      <>
                                        {contactCtg?.contactsCategoryName && (
                                          <h5 className="sp-selection-details__mediaTitle">
                                            {contactCtg?.contactsCategoryName}
                                          </h5>
                                        )}
                                        <ul>
                                          {contactCtg?.contactsList?.map(
                                            (point: any) => (
                                              <li>
                                                <a
                                                  href={`mailto:${point?.contactEmail}`}
                                                >
                                                  {point?.contactName}
                                                </a>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </>
                                    )
                                  )}
                                </Col>
                              </Row>
                            </DpSections>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </SelectionDetailsStyle>
      ) : (
        <SkeletonComponent />
      )}
    </ThemeProvider>
  );
};
export default SelectionDetails;
