import './footer.scss';
import logo from '../../assets/media/deloittelogo.png';
import { useTypedSelector } from '../../state/hooks/useTypedSelector';
import { Col, Row } from 'react-bootstrap';
import { FooterLinks } from './footer.styled';
import { color } from 'd3';

const Footer: React.FC = () => {
  const { background, highlight } = useTypedSelector(
    (state) => state.getThemeColor
  );
  return (
    <footer
      className="footer-container"
      style={{ backgroundColor: background }}
    >
      <Row>
        <Col className="footer-container__title-section">
          <div>
            The Plays <span className="sf-header__green-dot" />
          </div>
        </Col>
      </Row>
      <Row className="footer-container__link-section">
        <Col className="footer-container__linkGrid">
          <FooterLinks
            about={highlight}
            target="_blank"
            href="https://www.spx.ddstudios.us/"
          >
            SPX
          </FooterLinks>
          <FooterLinks
            about={highlight}
            target="blank"
            href="https://amedeloitte.sharepoint.com/sites/DeloitteUSIStudioPlaybook/SitePages/Home.aspx"
          >
            Deloitte USI Studios Playbook
          </FooterLinks>
          <FooterLinks about={highlight} href="/faq">
            FAQ
          </FooterLinks>
          <FooterLinks about={highlight} href="mailto:ustheplays@deloitte.com">
            Feedback
          </FooterLinks>
        </Col>
      </Row>

      <Row>
        <Col className="footer-container__text-section">
          <div className="footer-text">
            <span>
              {' '}
              Copyright © 2023. Deloitte Development LLC. All rights reserved.
            </span>
          </div>
          <div className="footer-container__logo">
            <img width={175} src={logo} />
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
