import { useEffect, useState } from 'react';
import backSVG from '../../assets/icons/back.svg';
import forwardSVG from '../../assets/icons/forward.svg';
import useDPApi from '../../lib/api';
import styles from './Pagination.module.scss';

const Pagination = (props: any) => {
  /**
   * need to rework on this
   */
  const { loadSearchQuery } = useDPApi();
  const [pageCount, setPageCount] = useState(1);

  const increment = () => {
    props.handleIncrement();
  };
  const decrement = () => {
    props.handleDecrement();
  };

  return (
    <div className={`${styles.pagination} d-flex`}>
      <p className={styles.pagination__label}>
        Page {props.currPage} of {props.currPage == 0 ? 0 : props.totalPages}
      </p>
      <div>
        <button
          disabled={props.currPage <= 1}
          onClick={decrement}
          className={styles.pagination_nav}
        >
          <img src={backSVG} alt="download" />
        </button>
        <button
          disabled={props.currPage >= props.totalPages || props.currPage == 0}
          onClick={increment}
          className={styles.pagination_nav}
        >
          <img src={forwardSVG} alt="download" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
