import axios from 'axios';
import { Dispatch } from 'redux';
import { Action, SetUserAuth } from '../actions';
import { ActionType } from '../action-types';
import { searchResults } from '../../models/play-details';

export const setUserAuth = (value: any) => {
  const resetData = {
    searchResultsCategories: [],
    searchResultsResponse: [],
    downloadFilesData: [],
  };
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_USER_AUTH,
      payload: value,
    });
  };
};
