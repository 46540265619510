import styled from 'styled-components';

export const ScrollLink = styled.a`
  &:hover {
    color: ${(props) => props.theme.color};
  }
`;

export const ScrollPanel = styled.div`
  border-right: 1px solid ${(props) => props.theme.color};
`;
