import axios from 'axios';
import { Dispatch } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../action-types';

export const loadChartData = (data: any = null) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.LOAD_CHART_DATA,
    });

    try {
      dispatch({
        type: ActionType.LOAD_CHART_DATA_SUCCESS,
        payload: data,
      });
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: ActionType.LOAD_CHART_DATA_ERROR,
          payload: err.message,
        });
      }
    }
  };
};

export const setChartSelection = (selection: any) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SUNBURST_SELECTION,
      payload: selection,
    });
  };
};
