import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

const SSORedirect = () => {
  const { instance } = useMsal();
  useEffect(() => {
    instance.loginRedirect().catch(console.error);
  }, []);
  return <div />;
};

export default SSORedirect;
