import navImage from '../../assets/media/nav-chart.svg';
import { useActions } from '../../state/hooks/useActions';

import './scrollspy-nav.scss';
import { ScrollLink, ScrollPanel } from './scrollspy.styled';

interface ScrollSpyNavPropsData {
  sectionDetails: { label: string; id: string }[];
}

const ScrollSpyNav: React.FC<ScrollSpyNavPropsData> = ({ sectionDetails }) => {
  const { toggleModal } = useActions();
  const bringIntoView = (e: any) => {
    e.preventDefault();
    document
      .getElementById(e.target.getAttribute('data-scroll-view'))
      ?.scrollIntoView();
  };
  const handleSunburstModal = () => {
    toggleModal(true);
  };
  return (
    <div className="sp-scrollspy-nav">
      <div
        onClick={handleSunburstModal}
        className="sp-scrollspy-nav__chart-img align-items-start justify-content-end d-md-flex "
      >
        <img src={navImage} alt="Navigation Image" className="nav-chart" />
      </div>
      <ScrollPanel
        className={`${
          sectionDetails?.length ? 'sp-scrollspy-nav__bordered-section' : ''
        } d-none d-md-block`}
      >
        {sectionDetails?.map(
          (item) =>
            item.label && (
              <ScrollLink
                className="sp-scrollspy-nav__link sidePanel"
                key={item.id}
                data-scroll-view={item.id}
                onClick={bringIntoView}
              >
                {item.label}
              </ScrollLink>
            )
        )}
      </ScrollPanel>
    </div>
  );
};

export default ScrollSpyNav;
