import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const SolutionLink = styled.a`
  background-color: ${(props) => props.theme.mediaItemsBackdrop};
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.color};
  }
`;
