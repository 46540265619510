import { MutableRefObject, useEffect, useRef, useState } from 'react';
import './AppDrawer.scss';
import SmallCross from '../../assets/icons/cross_small.svg';

const AppDrawer = (props: any) => {
  const appDrawerRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (props.show) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [props.show]);

  const handleDrawer = () => {
    props.closeDrawer(false);
  };
  return (
    <>
      <div className="dp__drawer d-md-none">
        {props.show && (
          <div className="dp__drawer--backdrop" onClick={handleDrawer} />
        )}
        <div
          ref={appDrawerRef}
          className={`dp__drawer--overlay${props.show ? ' drawer-open' : ''}`}
        >
          <img
            className="dp__drawer__closeBtn"
            onClick={handleDrawer}
            src={SmallCross}
            alt="close-menu"
          />
          {props.children}
        </div>
      </div>
    </>
  );
};

export default AppDrawer;
