import { MediaItemsDetailsSchema } from '../../../models/play-details';
import { useTypedSelector } from '../../../state/hooks/useTypedSelector';

import './downloads.scss';
import { DownloadLabel, DownloadStyled } from './Downloads.styled';

interface DownloadSectionPropTypes {
  downloads: MediaItemsDetailsSchema[];
}
const DownloadsSection: React.FC<DownloadSectionPropTypes> = ({
  downloads,
}: any) => {
  const { userAssetApi } = useTypedSelector((state: any) => state.setUserAuth);

  const handleDownload = async (asset: any, fileName: any, assetLink: any) => {
    if (asset) {
      const signedURLRes = await userAssetApi?.getAssetSignedUrl(
        asset,
        fileName
      );
      const url = signedURLRes?.data;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
    } else {
      window.open(process.env.PUBLIC_URL + assetLink, '_blank');
    }
  };
  return (
    <DownloadStyled className="sp-dwnlds">
      {downloads?.map((item: any) => (
        <DownloadLabel
          // href={process.env.PUBLIC_URL + item.downloadLink}
          onClick={() => {
            handleDownload(
              item?.assetID || item?.dowanloadAsset,
              item?.downloadName,
              item.downloadLink
            );
          }}
          key={item.downloadName}
          className="sp-dwnlds__item"
          download
        >
          <i className="bi bi-file-arrow-down" /> {item.downloadName}
        </DownloadLabel>
      ))}
    </DownloadStyled>
  );
};

export default DownloadsSection;
