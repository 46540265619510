import { ActionType } from '../action-types';
import { Action } from '../actions';

interface DataState {
  loading: boolean;
  error: string | null;
  data: any | null;
}

const initialState: DataState = {
  loading: false,
  error: null,
  data: null,
};

const phaseDetailsReducer = (
  state: DataState = initialState,
  action: Action
): DataState => {
  switch (action.type) {
    case ActionType.LOAD_PHASE_DETAILS:
      return { loading: true, error: null, data: null };
    case ActionType.LOAD_PHASE_DETAILS_SUCCESS:
      return { loading: false, error: null, data: action.payload };
    case ActionType.LOAD_PHASE_DETAILS_ERROR:
      return { loading: false, error: action.payload, data: null };
    default:
      return state;
  }
};

export default phaseDetailsReducer;
