import * as d3 from 'd3';

export default function ({
  chartData,
  svgRef,
  contentSectionRef,
  buttonRef,
  contentWrap,
  videoSectionRef,
  setChartSelection,
  navigate,
  showContent,
  chartSelection,
  svgContanerRef,
  customClickHandler,
}) {
  const width = 600;
  const radius = width / 8;
  const arc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 1.5)
    .innerRadius((d) => d.y0 * radius)
    .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

  const partition = (data) => {
    let root;
    root = d3.hierarchy(data).sum((d) => d.value);
    // .sort((a, b) => b.value - a.value);
    return d3.partition().size([2 * Math.PI, root.height + 1])(root);
  };

  const data = chartData;
  const root = partition(data);
  root.each((d) => (d.current = d));
  const svg = d3
    .select(svgRef.current)
    .attr('viewBox', [0, 0, 600, 600])
    // .style('width', '100%')
    .style('height', 'auto')
    .style('font', '11px sans-serif');

  const centerText = svg
    .append('g')
    .attr('transform', 'translate(' + width / 2 + ',' + width / 1.99 + ')')
    .style('cursor', 'pointer');

  centerText
    .append('text')
    .text('Home')
    .attr('text-anchor', 'middle')
    .attr('font-size', '1.5em')
    .style('font', '11px sans-serif')
    .style('opacity', '0.6')
    .attr('fill', 'white');

  const g = svg
    .append('g')
    .attr('transform', `translate(${width / 2},${width / 2})`);

  const path = g
    .append('g')
    .selectAll('path')
    .data(root.descendants().slice(1))
    .join('path')
    .style('fill', function (d) {
      return d.data.color || 'red';
    })
    .attr('fill-opacity', (d) =>
      arcVisible(d.current) ? (d.children ? 1 : 1) : 0
    )
    .attr('d', (d) => arc(d.current));

  path
    .filter((d) => d.children)
    .style('cursor', 'pointer')
    .on('click', clicked.bind({ contentSectionRef, videoSectionRef }));
  path
    .filter((d) => !d.children)
    .style('cursor', 'pointer')
    .on('click', lastLevelClick.bind({ contentSectionRef, videoSectionRef }));
  path.append('title').text(
    (d) =>
      `${d
        .ancestors()
        .map((d) => d.data.name)
        .reverse()
        .join('/')}`
  );

  const label = g
    .append('g')
    .attr('pointer-events', 'none')
    .attr('text-anchor', 'middle')
    .style('user-select', 'none')
    .selectAll('text')
    .data(root.descendants().slice(1))
    .join('text')
    .attr('dy', '-4')
    .attr('fill-opacity', (d) => +labelVisible(d.current))
    .attr('transform', (d) => labelTransform(d.current));
  // .text((d) => d.data.name);

  // Added to wrap the labels in sunburst chart from line 404 to 414
  label
    .append('tspan')
    .attr('x', 0)
    .text(function (d) {
      try {
        return d.depth ? d.data.name.split(' ')[0] : '';
      } catch (err) {
        console.error('error adding label details to sunburst', err);
      }
    });
  label
    .append('tspan')
    .attr('x', 0)
    .attr('dy', '11px')
    .text(function (d) {
      try {
        return d.depth ? d.data.name.split(' ')[1] || '' : '';
      } catch (err) {
        console.error('error adding label details to sunburst', err);
      }
    });
  label
    .append('tspan')
    .attr('x', 0)
    .attr('dy', '11px')
    .text(function (d) {
      try {
        return d.depth && d.data.name.split(' ')[2]
          ? `${d.data.name.split(' ')[2]}..` || ''
          : '';
      } catch (err) {
        console.error('error adding label details to sunburst', err);
      }
    });
  // label
  //   .append('tspan')
  //   .attr('x', 0)
  //   .attr('dy', '11px')
  //   .text(function (d) {
  //     return d.depth ? d.data.name.split(' ')[3] || '' : '';
  //   });

  // label
  //   .append('tspan')
  //   .attr('x', 0)
  //   .attr('dy', '11px')
  //   .text(function (d) {
  //     return d.depth ? d.data.name.split(' ')[4] || '' : '';
  //   });

  const parent = g
    .append('circle')
    .datum(root)
    .attr('r', radius)
    .attr('fill', 'none')
    .attr('pointer-events', 'all')
    .attr('cursor', 'pointer')
    .on('click', clicked.bind({ contentSectionRef, videoSectionRef }));
  //---------------------------------------------------------------------------------------------------
  function handleDescriptonDisplay(data) {
    if (data.content) {
      data.content.map((item) => {
        const descWrapper = document.createElement('div');
        if (item.title) {
          descWrapper.innerHTML += `<h2 class='sectionTitle'>${item.title}</h2>`;
        }
        descWrapper.innerHTML += item.text;
      });
    }
  }

  function lastLevelClick(p) {
    setChartSelection(p);
    if (customClickHandler) {
      customClickHandler(p);
    }

    const button = buttonRef?.current
      ? buttonRef?.current
      : document.getElementById('viewMore');
    button?.setAttribute('data-navigate', p.data.name);
  }

  function clicked(p) {
    setChartSelection(p);
    const button = buttonRef?.current
      ? buttonRef?.current
      : document.getElementById('viewMore');
    if (p.data.name !== 'Explore Delivery Plays') {
      button?.setAttribute('data-navigate', p.data.name);
    } else {
      button?.removeAttribute('data-navigate');
    }
    parent.datum(p.parent || root);
    if (customClickHandler) {
      customClickHandler(p);
    } else {
      root.each(
        (d) =>
          (d.target = {
            x0:
              Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            x1:
              Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth),
          })
      );

      const t = g.transition().duration(750);

      // Transition the data on all arcs, even the ones that aren't visible,
      // so that if this transition is interrupted, entering arcs will start
      // the next transition from the desired position.
      path
        .transition(t)
        .tween('data', (d) => {
          if (p.current ? (p.children ? 0 : 1) : 0) {
            return false;
          }
          const i = d3.interpolate(d.current, d.target);
          return (t) => (d.current = i(t));
        })
        .filter(function (d) {
          return +this.getAttribute('fill-opacity') || arcVisible(d.target);
        })
        .attr('fill-opacity', (d) =>
          arcVisible(d.target) ? (d.children ? 1 : 1) : 0
        )
        .attrTween('d', (d) => () => arc(d.current));

      label
        .filter(function (d) {
          return +this.getAttribute('fill-opacity') || labelVisible(d.target);
        })
        .transition(t)
        .attr('fill-opacity', (d) => +labelVisible(d.target))
        .attrTween('transform', (d) => () => labelTransform(d.current))
        .on('end', () => {
          document.getElementById('partitionSVG').style.visibility = 'visible';
        });
    }

    // const element = contentSectionRef.current;
    // element.style.display = 'block'
  }

  function arcVisible(d) {
    return d.y1 <= 4 && d.y0 >= 1 && d.x1 > d.x0;
  }

  function labelVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
  }

  function labelTransform(d) {
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y = ((d.y0 + d.y1) / 2) * radius;
    // return `rotate(${x - 90}) translate(${y},0) rotate(${90})`;
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
  }
  return svg.node();
}
