import { Dispatch } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../action-types';

export const loadCaseResources = (
  data: any = { resources: [], totalPages: 0 }
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.LOAD_RESOURCES,
    });

    try {
      dispatch({
        type: ActionType.LOAD_RESOURCES_SUCCESS,
        payload: data,
      });
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: ActionType.LOAD_RESOURCES_ERROR,
          payload: err.message,
        });
      }
    }
  };
};
