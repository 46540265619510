import { ActionType } from '../action-types';
import { Action } from '../actions';

const authReducer = (state = null, action: Action) => {
  switch (action.type) {
    case ActionType.SET_USER_AUTH:
      return {
        userApi: action.payload.userApi,
        userAssetApi: action.payload.userAssetApi,
        userEngagementApi: action.payload.userEngagementApi,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

export default authReducer;
