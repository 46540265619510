import { ActionType } from '../action-types';
import { Action } from '../actions';

const initialState: any = null;

const searchQueryReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.LOAD_SEARCH_QUERY:
      return action.payload;
    default:
      return state;
  }
};

export default searchQueryReducer;
