import { Col, Dropdown, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const PhaseDetailStyled = styled.div`
  background-color: ${(props) => props.theme.background};
`;

export const PhaseSelectionStyled = styled.a`
  border-bottom: ${(props) =>
    props.about
      ? props?.about === 'active'
        ? `1px solid ${props.theme.active}`
        : '1px solid #FFFF'
      : 'none'};
  color: ${(props) =>
    props.about
      ? props?.about === 'active'
        ? `${props.theme.active}`
        : '#FFFF'
      : '#FFFF'};
  cursor: pointer;
  &:hover {
    color: ${(props) =>
      props.about
        ? props?.about === 'active'
          ? `${props.theme.active}`
          : '#FFFF'
        : '#FFFF'};
  }
`;

export const MobileSelectionStyled = styled.a`
  border: ${(props) =>
    props.about ? `1px solid ${props.theme.active}` : '1px solid #FFFF'};
  color: ${(props) => (props.about ? props.theme.active : '#FFFF')};
  padding: 8px 16px;
  margin: 12px 10px 16px 0;
  color: #ffff;
  &:hover {
    color: ${(props) => (props.about ? props.theme.active : '#FFFF')};
  }
`;

export const PhaseDetailRowStyled = styled(Row)`
  border-bottom-color: ${(props) => props.theme.borderColor};
`;

export const PhaseDetailColStyled = styled(Col)`
  border-right-color: ${(props) => props.theme.borderColor};
`;

export const PhaseDetailContainerStyled = styled(Row)`
  border-top-color: ${(props) => props.theme.borderColor};
`;

export const PhaseDetailTitleStyled = styled.span`
  color: ${(props) => props.theme.highlight};
`;

export const NoPhaseDataStyled = styled.div`
  color: ${(props) => props.theme.highlight};
`;

export const DropDownMobile = styled(Dropdown.Menu)`
  background-color: ${(props) => props.theme.borderColor};
`;
export const SubPhaseDropDown = styled(Dropdown.Toggle)`
  color: ${(props) => props.theme.active};
`;
export const DropDownItemStyled = styled(Dropdown.Item)`
  color: ${(props) => (props.isselected ? props.theme.active : '#FFFF')};
`;
