import styled from 'styled-components';

export const DownloadLabel = styled.a`
  &:hover {
    color: ${(props) => props.theme.color};
  }
`;

export const DownloadStyled = styled.div`
  background-color: ${(props) => props.theme.mediaItemsBackdrop};
`;
