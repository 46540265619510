import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useActions } from '../../state/hooks/useActions';
import { useLocation } from 'react-router-dom';
import { useTypedSelector } from '../../state/hooks/useTypedSelector';
import Hero from '../hero/Hero';
import Sunburst from '../sunburst/Sunburst';
import './LandingPage.scss';
import { spStringConstants } from '../../Utilities/sp-strings';
import useDPApi from '../../lib/api';

const LandingPage = () => {
  const data = useTypedSelector((state) => state.setLandingPageContent);
  const { loadLandingPage } = useDPApi();
  const { setTheme } = useActions();
  const location = useLocation();
  // useEffect(() => {
  //   trackCurrentPage();
  // }, [location]);
  useEffect(() => {
    loadLandingPage();
    setTheme({
      background: spStringConstants?.initialFooterTheme,
      highlight: '#ffff',
    });
  }, []);
  const { masterChartData } = useTypedSelector((state) => state.chartData);
  return (
    <>
      <Hero heroData={data} />
      <div className="sunburst-wrapper" id="sunburstWrapper">
        <Container className="dp-landing-page">
          <div className="sunburst-wrapper__text animated animatedFadeInUp fadeInUp sunburst-wrapper__title">
            <p className="dp-landing-page__eyebrow title-eyebrow">
              {data?.sunBurst?.titleEyebrow}
            </p>
            <h1 className="dp-landing-page__title title">
              {data?.sunBurst?.title}
            </h1>
            {/* <p className="sub-heading">Explore and understand how we take these to market.</p> */}
          </div>

          {masterChartData ? (
            <Sunburst
              masterChartData={masterChartData}
              showContent={true}
              stopAnimation={false}
            />
          ) : (
            <div className="sunburst-skeleton"></div>
          )}
        </Container>
      </div>
    </>
  );
};

export default LandingPage;
