import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './Header.scss';
import { NavDropdown } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { spStringConstants } from '../../Utilities/sp-strings';
import AppDrawer from '../appDrawer/AppDrawer';
import AccordionComponent from '../Accordion/Accordion';
import DpSearch from '../DpSearch/Dp-Search';
import navImage from '../../assets/media/nav-chart.svg';
import { useActions } from '../../state/hooks/useActions';
import Search from '../../assets/icons/Search.svg';
import SmallCross from '../../assets/icons/cross_small.svg';
import { useTypedSelector } from '../../state/hooks/useTypedSelector';
import useDPApi from '../../lib/api';

const Header: React.FC = () => {
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const { toggleModal } = useActions();
  const params = useLocation();
  const [toggleSrch, setToggleSrch] = useState(true);
  const { loadPlayData } = useDPApi();
  const { data } = useTypedSelector((state) => state.playDetails);
  const { contentApi } = useTypedSelector((state) => state.setUserAuth);
  const handleAppDrawer = (flag: boolean) => {
    setToggleDrawer(flag);
  };
  const handleSunburstModal = () => {
    toggleModal(true);
    setToggleDrawer(false);
  };

  const toggleSearch = () => {
    setToggleSrch((flag) => (flag = !flag));
  };

  useEffect(() => {
    loadPlayData();
  }, []);

  let playDetailsArray: any = data?.map((play: any) => {
    return play?.title;
  });

  const playArray1 = playDetailsArray?.slice(0, 3);
  const playArray2 = playDetailsArray?.slice(3, 6);

  playDetailsArray = [playArray1, playArray2];

  const salesPlayMenuItems = !toggleDrawer ? (
    <NavDropdown
      title="Our Delivery Plays"
      className={`sf-header--0 ` + 'd-none d-md-block'}
      id="basic-nav-dropdown"
    >
      <div className="sf-header--navwrap">
        {playDetailsArray?.map((items: any) => (
          <ul className="sf-header__list">
            {items?.map((itemLabel: any) => (
              <li>
                <NavDropdown.Item as={Link} to={`${`/${itemLabel}`}`}>
                  {itemLabel.toUpperCase()}
                </NavDropdown.Item>
              </li>
            ))}
          </ul>
        ))}
      </div>
      <p className="sf-header__quote d-none d-md-block">
        Looking for quick resources for your project? Use the sunburst below to
        download quick resources
      </p>
    </NavDropdown>
  ) : (
    <AccordionComponent title="Our Delivery Plays">
      {playDetailsArray?.map((item: any) => {
        return (
          <ul className="sf-header__list sf-header__list--mobile">
            {item?.map((itemLabel: any) => (
              <li className="sf-header__list--mobile__item mb-4">
                <NavLink
                  onClick={() => {
                    setToggleDrawer((flag) => (flag = !flag));
                  }}
                  to={`${`/${itemLabel}`}`}
                >
                  {itemLabel.toUpperCase()}
                </NavLink>
              </li>
            ))}
          </ul>
        );
      })}
    </AccordionComponent>
  );

  const caseStudyResourcesItems = !toggleDrawer ? (
    <NavDropdown
      title={spStringConstants?.spHeaderData?.title}
      className={`sf-header--1 ` + 'd-none d-md-block'}
      id="basic-nav-dropdown"
    >
      <div className="sf-header--navwrap">
        {spStringConstants?.spHeaderData?.items?.map((items: any) => (
          <ul className="sf-header__list">
            {items?.map((itemLabel: any) => (
              <li>
                <NavDropdown.Item
                  as={Link}
                  to={`/caseStudy&resources?filter=${itemLabel.urlFilter}`}
                >
                  {itemLabel.text.toUpperCase()}
                </NavDropdown.Item>
              </li>
            ))}
          </ul>
        ))}
      </div>
      <p className="sf-header__quote d-none d-md-block">
        Looking for quick resources for your project? Use the sunburst below to
        download quick resources
      </p>
    </NavDropdown>
  ) : (
    <AccordionComponent title={spStringConstants?.spHeaderData?.title}>
      {spStringConstants?.spHeaderData?.items?.map((item: any) => {
        return (
          <ul className="sf-header__list sf-header__list--mobile">
            {item?.map((itemLabel: any) => (
              <li className="sf-header__list--mobile__item mb-4">
                <NavLink
                  onClick={() => {
                    setToggleDrawer((flag) => (flag = !flag));
                  }}
                  to={`/caseStudy&resources?filter=${itemLabel.urlFilter}`}
                >
                  {itemLabel.text.toUpperCase()}
                </NavLink>
              </li>
            ))}
          </ul>
        );
      })}
    </AccordionComponent>
  );

  return (
    <>
      <Navbar bg="dark" variant="dark" className="sf-header" expand="md">
        <Container>
          {toggleSrch && (
            <Navbar.Brand className="sf-header__navBar--brand" as={Link} to="/">
              The Plays <span className="sf-header__green-dot" />
            </Navbar.Brand>
          )}
          <div
            className={`${
              !toggleSrch ? 'mobile-search' : ''
            } d-flex justify-content-between`}
          >
            {!toggleSrch && (
              <>
                <DpSearch closeAppDrawer={handleAppDrawer} />
              </>
            )}
            <div className="d-md-none">
              <img
                onClick={toggleSearch}
                src={toggleSrch ? Search : SmallCross}
                alt="Search-icon"
              />
            </div>
          </div>
          {toggleSrch && (
            <>
              <Navbar.Toggle
                aria-controls="sf-header-collapse"
                onClick={() => setToggleDrawer((flag) => (flag = !flag))}
              />
              <Nav className="sf-header__navbar justify-content-evenly d-md-flex d-none">
                {salesPlayMenuItems}
                {caseStudyResourcesItems}
              </Nav>
              <div className="d-none d-md-block sf-header__searchWrapper">
                <DpSearch />
              </div>
            </>
          )}
        </Container>
      </Navbar>
      <AppDrawer show={toggleDrawer} closeDrawer={handleAppDrawer}>
        <div className="sf-header__accordionWrapper">
          {salesPlayMenuItems}
          {caseStudyResourcesItems}
        </div>
        {/* {params.pathname !="/" && <div onClick={handleSunburstModal} className='px-5 py-5'>
      <img src={navImage} alt="Navigation" className="sf-header__nav-chart" />
      </div>} */}
      </AppDrawer>
    </>
  );
};
export default Header;
