import { Col, Row } from 'react-bootstrap';
import Checkbox from '../Checkbox/checkbox';
import downloadIcon from '../../assets/icons/Download.svg';
import styles from './FileDownload.module.scss';
import { useEffect, useState } from 'react';
import { useTypedSelector } from '../../state/hooks/useTypedSelector';

const FileDownloadComponent = (props: any) => {
  const [fileData, setFileData] = useState(props.fileData);
  const handleSelectedFile = (checked: boolean) => {
    // const { id, title, asset } = formattedData;
    // props.sendSelectedFile({ id, checked, title, asset });
  };

  useEffect(() => {
    const { createdAt, asset } = props.fileData;
    const date = new Date(createdAt);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    setFileData({ ...props.fileData, formattedDate });
  }, [props.fileData]);
  return (
    <>
      <>
        <Row>
          <Col>
            <Checkbox onCheck={handleSelectedFile} title={fileData?.title} />
          </Col>
          <Col lg={5}>
            <div className={styles.file_section}>
              <span className="text-right" style={{ textAlign: 'right' }}>
                <label className="regular">{fileData?.formattedDate}</label>
              </span>
              <span className="text-right" style={{ textAlign: 'right' }}>
                <label className="regular">{fileData?.contentType}</label>
              </span>
              <div className="text-right" style={{ textAlign: 'right' }}>
                {/* <a
                  className={styles.fileDownload__download}
                  href={fileData?.asset}
                  // target="_blank"
                  rel="noreferrer"
                >
                  <img src={downloadIcon} alt="download" />
                </a> */}
                <div
                  className={`text-right ${styles.fileDownload__download}`}
                  style={{ textAlign: 'right' }}
                  onClick={() => {
                    props.handleDownload(fileData?.asset, fileData?.title);
                  }}
                >
                  <img src={downloadIcon} alt="download" />
                </div>
              </div>
              {/* <div className={styles.fileDownload__download}>
                <a href={process.env.PUBLIC_URL} download>
                  <img src={downloadIcon} alt="download" />
                </a>
              </div> */}
            </div>
          </Col>
        </Row>
        <p className={`${styles.fileDownload__description} sub-heading`}>
          {fileData?.description}
        </p>
        <div className={styles.fileDownload}></div>
      </>

      {/* <div className={styles.fileDownload__title +` d-flex justify-content-between`}>
    //   <Checkbox onCheck = {handleSelectedFile} title = {title}/>
    //   <div className="d-flex justify-content-between">
    //     <div>
    //     <a className={styles.fileDownload__view} href= {src} target="_blank"><label className="bold">View</label></a>
    //     </div>
    //     <div className={styles.fileDownload__download}>
    //   <a href= {process.env.PUBLIC_URL + src} download><img src={downloadIcon} alt="download" /></a>
    // </div>
    //   </div>
    // </div>
    // <p className={styles.fileDownload__description +" sub-heading"}>{description}</p> */}
      {/* </div> */}
    </>
  );
};

export default FileDownloadComponent;
