import axios from 'axios';
import { Dispatch } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../action-types';
import { PlayDetails, searchResults } from '../../models/play-details';

export const selectFiles = (fileData: any) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FILE_SELECTION,
      payload: fileData,
    });
  };
};
