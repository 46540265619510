import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { NavLink } from 'react-router-dom';
import styles from './Search-results-card.module.scss';

const SearchResultCard = (props: any) => {
  const date = new Date(props?.data?.updatedAt);
  return (
    <div className={styles.searchResultCard}>
      <div className={styles.searchResultCard__heading}>
        <NavLink
          className={styles.searchResultCard__heading_title}
          to={
            props?.data?.categoryType === 'play'
              ? `/${props?.data?.title}`
              : `/${props?.data?.phaseid}/phases`
          }
        >
          <h5>{props?.data?.title}</h5>
        </NavLink>
      </div>
      <div className={styles.searchResultCard__description}>
        <p>{`Published on ${date.toDateString()}`}</p>
        {props?.data?.categoryType !== 'play' && (
          <p
            dangerouslySetInnerHTML={{
              __html: props?.data?.description,
            }}
          />
        )}
        <p
          dangerouslySetInnerHTML={{
            __html: props?.data?._formatted?.summary?.description?.md,
          }}
        />
        <div className="select">
          {/* <ReactMarkdown>{(props?.data?._formatted?.keyDifferentiators?.description)?.replace(regex,"")}</ReactMarkdown> */}
          {/* <ReactMarkdown>{(props?.data?._formatted?.keyDifferentiators?.description)?.replace(regex,"")}</ReactMarkdown> */}
        </div>
      </div>
    </div>
  );
};

export default SearchResultCard;
