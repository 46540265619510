import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import './CaseStudy.scss';
import { useEffect, useRef, useState } from 'react';
import deloitte from '../../assets/media/caseStudies.png';
import BadgeComponent from '../Badges/BadgeComponent';
import downloadIcon from '../../assets/icons/Download.svg';
import Pagination from '../Pagination/Pagination';
import FileDownloadComponent from '../FileDownlaodComponent/FileDownloadComponent';
// import SalesPlayCard from '../SalesPlayCard/SalesPlayCard';
import { useActions } from '../../state/hooks/useActions';
import { spStringConstants } from '../../Utilities/sp-strings';
import useDPApi from '../../lib/api';
import { useTypedSelector } from '../../state/hooks/useTypedSelector';
import { useSearchParams } from 'react-router-dom';
import {
  DropDownItemStyled,
  DropDownMobile,
  SubPhaseDropDown,
} from '../phase-detail/PhaseDetail.styled';
import { CSRDropdownItem } from './caseStudy.styled';
import { isNonNullExpression } from 'typescript';

const CaseStudyDetails = (props: any) => {
  const Badges = {
    topic: [
      { title: 'Benchmarks & Assessments', isselected: false },
      { title: 'Composable Experiences', isselected: false },
      { title: 'Enterprise UX', isselected: false },
      { title: 'Experiences & Products @ Scale', isselected: false },
      { title: 'Experience Vision', isselected: false },
      { title: 'Product Incubation', isselected: false },
    ],

    contentType: [
      { title: 'Case Studies', isselected: false },
      { title: 'Resources', isselected: false },
      { title: 'Assets', isselected: false },
      { title: 'Accelerators', isselected: false },
    ],

    sort: [
      { title: 'Recently Added', isselected: true },
      // { title: 'Popularity', isselected: false },
    ],
  };
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const resourcesData = useTypedSelector((state) => state.setResources);
  const [currPage, setCurrPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [caseStudies, setCaseStudies] = useState<any[]>([]);
  const [totalPagesData, setTotalPagesData] = useState(0);
  const { userAssetApi } = useTypedSelector<any>((state) => state.setUserAuth);
  const [badgeLabel, setBadgeLabel] = useState<string | null>(null);
  useEffect(() => {
    // const filter = searchParams.get('filter');
    // if (filter) {
    //   handleBadgeClick('contentTypes', filter);
    // } else {
    //   handleBadgeClick('contentTypes', '');
    // }

    setTheme({
      background: spStringConstants?.neutralFooterTheme,
      highlight: '#ffff',
    });
  }, []);

  useEffect(() => {
    if (filter) {
      handleBadgeClick('contentTypes', filter, true);
    } else {
      handleBadgeClick('contentTypes', '', true);
    }
  }, [filter]);

  useEffect(() => {
    if (resourcesData) {
      const { resources, totalPages } = resourcesData;
      setCaseStudies(resources);
      setTotalPagesData(totalPages);
      setLoader(false);
    }
  }, [resourcesData]);
  const { loadResources } = useDPApi();
  const { setTheme } = useActions();

  const [topicFilterList, setTopicFilterList] = useState<any[]>([]);
  const [contentFilterList, setContentFilterList] = useState<any[]>([]);
  // const [sortFilterList, setTopicFilterList] = useState([]);
  const [topics, setTopics] = useState(Badges.topic);
  const [contentTypes, setContentTypes] = useState(Badges.contentType);
  const [sort, setSort] = useState(Badges.sort);

  const topicLabel = topics.map((topic: any) => {
    return topic?.isselected ? ` ${topic.title}` : '';
  });

  const sortLable = sort.map((sortType: any) => {
    return sortType?.isselected ? ` ${sortType.title}` : '';
  });

  const contentLabel = contentTypes.map((content: any) => {
    return content?.isselected ? ` ${content.title}` : '';
  });

  const handleBadgeClick = (
    type: 'topics' | 'contentTypes' | 'sort',
    selectedBadge: string | null,
    isPageRefresh?: boolean
  ) => {
    setBadgeLabel(selectedBadge);
    if (isPageRefresh) {
      const updatedTopicList = topics.map((subType) => {
        subType.isselected = false;

        return subType;
      });
      setTopics(updatedTopicList);
      const filterSelectedTopic: any[] = [];
      setTopicFilterList(filterSelectedTopic);
      const updatedContentTypeList = contentTypes.map((subType) => {
        subType.isselected = false;
        return subType;
      });
      const filterSelectedContent: any[] = [];

      setContentFilterList(filterSelectedContent);
      setContentTypes(updatedContentTypeList);
    }
    if (selectedBadge) {
      setCaseStudies([]);
      setTotalPagesData(0);
      let filterSelectedTopic, updatedContentTypeList, filterSelectedContent;
      if (type == 'topics') {
        const updatedTopicList = topics.map((subType) => {
          if (subType.title === selectedBadge)
            subType.isselected = !subType.isselected;

          return subType;
        });
        setTopics(updatedTopicList);
        filterSelectedTopic = updatedTopicList
          .filter((topic) => topic.isselected)
          ?.map((topicData) => {
            return topicData.title;
          });
        setTopicFilterList(filterSelectedTopic);
      } else if (type == 'contentTypes') {
        updatedContentTypeList = contentTypes.map((subType) => {
          if (subType.title === selectedBadge)
            subType.isselected = !subType.isselected;
          return subType;
        });
        filterSelectedContent = updatedContentTypeList
          .filter((content) => content.isselected)
          ?.map((contentData) => {
            return contentData.title;
          });
        setContentFilterList(filterSelectedContent);
        setContentTypes(updatedContentTypeList);
      }
      loadResources({
        topics: filterSelectedTopic,
        content: filterSelectedContent,
      });
    } else {
      loadResources(userAssetApi);
    }
    setLoader(true);
  };

  const handleIncrement = () => {
    const newPage = currPage + 1;
    setCurrPage(newPage);
    loadResources(
      {
        topics: topicFilterList,
        content: contentFilterList,
      },
      newPage
    );
    setLoader(true);
  };

  const handleDecrement = () => {
    const newPage = currPage - 1;
    setCurrPage(newPage);
    loadResources(
      {
        topics: topicFilterList,
        content: contentFilterList,
      },
      newPage
    );
    setLoader(true);
  };

  const handleDownload = async (asset: any, fileName: any) => {
    const signedURLRes = await userAssetApi?.getAssetSignedUrl(asset, fileName);
    const url = signedURLRes?.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
  };
  return (
    <>
      <div className="caseStudyResources">
        <div className="caseStudyResources__hero">
          <Container>
            <Row className="d-flex justify-content-center align-items-baseline">
              <Col md={3}>
                <div>
                  <h1>Case Studies & Resources</h1>
                </div>
              </Col>
              <Col sm={12} lg={9} md={2}>
                <div className="caseStudyResources__rectangle">
                  <img src={deloitte} alt="rectangle" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {loader && (
          <div className="loader">
            <div className="loader-2 center">
              <span></span>
            </div>
          </div>
        )}
        <Container>
          <div className="caseStudyResources__dropDownWrapper d-flex flex-wrap d-md-none">
            <div className="caseStudyResources__dropDownOne">
              <label>Topic</label>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  value="Select"
                  className="caseStudyResources__dropDownButton d-flex justify-content-between align-items-center"
                >
                  {(topicFilterList.length > 0
                    ? ` ${topicFilterList.join(', ')}`
                    : 'Select'
                  ).replace(/^([\s\S]{0,50}).*/, '$1...')}
                </Dropdown.Toggle>
                <Dropdown.Menu className="caseStudyResources__dropDownMenu">
                  {topics?.map((topic: any) => (
                    <CSRDropdownItem
                      isselected={topic?.isselected ? 'selected' : ''}
                      onClick={() => handleBadgeClick('topics', topic.title)}
                    >
                      {topic?.title}
                    </CSRDropdownItem>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="caseStudyResources__dropDownTwo">
              <label>Content Type</label>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  value="here"
                  className="caseStudyResources__dropDownButton d-flex justify-content-between align-items-center"
                >
                  {(contentFilterList.length > 0
                    ? ` ${contentFilterList.join(' & ')}`
                    : 'All Documents'
                  ).replace(/^([\s\S]{0,19}).*/, '$1...')}{' '}
                </Dropdown.Toggle>
                <Dropdown.Menu className="caseStudyResources__dropDownMenu ">
                  {contentTypes?.map((contentType: any) => (
                    <CSRDropdownItem
                      isselected={contentType?.isselected ? 'selected' : ''}
                      onClick={() =>
                        handleBadgeClick('contentTypes', contentType?.title)
                      }
                    >
                      {contentType?.title}
                    </CSRDropdownItem>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="caseStudyResources__dropDownThree">
              <label>Sort By</label>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  value="here"
                  className="caseStudyResources__dropDownButton d-flex justify-content-between align-items-center"
                >
                  Recently Added
                </Dropdown.Toggle>
                <Dropdown.Menu className="caseStudyResources__dropDownMenu">
                  {sort?.map((sortType: any) => (
                    <CSRDropdownItem
                      isselected={sortType?.isselected ? 'selected' : ''}
                      onClick={() => handleBadgeClick('sort', sortType?.title)}
                    >
                      {sortType?.title}
                    </CSRDropdownItem>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="caseStudyResources__filters d-none d-md-block">
            <div className="caseStudyResources__filters--rowOne  d-flex justify-content-between align-items-center">
              <div className="title">
                <label className="regular">Topic</label>
              </div>
              <div className="d-flex flex-wrap flex-gap">
                {topics.map((topic) => (
                  <>
                    <button
                      className={`badgeComponent ${
                        topic.isselected ? 'selected' : ''
                      }`}
                      onClick={() => handleBadgeClick('topics', topic.title)}
                    >
                      <p className="description-regular">{topic.title}</p>
                    </button>
                  </>
                ))}
              </div>
            </div>
            <div className="caseStudyResources__filters--rowTwo  d-flex justify-content-between flex-wrap">
              <div className="d-flex align-items-center">
                <div className="caseStudyResources__filters--contentLabel">
                  <label className="regular">Content Type</label>
                </div>
                <div className="d-flex flex-wrap flex-gap">
                  {contentTypes.map((contentType) => (
                    <>
                      <button
                        className={`badgeComponent ${
                          contentType.isselected ? 'selected' : ''
                        }`}
                        onClick={() =>
                          handleBadgeClick('contentTypes', contentType.title)
                        }
                      >
                        <p className="description-regular">
                          {contentType.title}
                        </p>
                      </button>
                    </>
                  ))}
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <div className="caseStudyResources__filters--sortLabel">
                  <label className="regular">Sorted By</label>
                </div>
                <div>
                  {sort.map((sortType) => (
                    <>
                      <button
                        className={`badgeComponent ${
                          sortType.isselected ? 'selected' : ''
                        }`}
                      >
                        <p className="description-regular">{sortType.title}</p>
                      </button>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="caseStudyResources__actionPanel d-flex justify-content-between align-items-end flex-wrap">
            <div>
              <p>
                Showing{' '}
                <span>
                  {contentFilterList.length > 0
                    ? `All ${contentFilterList.join(' & ')}`
                    : 'All Documents'}{' '}
                </span>
                {topicFilterList.length > 0
                  ? `for ${topicFilterList.join(', ')}`
                  : ''}
              </p>
            </div>

            <div className="d-flex justify-content-between">
              <Pagination
                handleIncrement={handleIncrement}
                handleDecrement={handleDecrement}
                totalPages={totalPagesData}
                currPage={caseStudies.length == 0 ? 0 : currPage}
                page={'caseStudy'}
              />
              {/* <div className="caseStudyResources__downloadBtn--wrapper">
                <a className="caseStudyResources__downloadBtn">
                  <label className="bold">
                    <span>
                      <img
                        className="caseStudyResources__downloadIcon"
                        src={downloadIcon}
                        alt="Download Selected"
                      />
                    </span>
                  </label>
                </a>
              </div> */}
            </div>
          </div>
          <div className="caseStudyResources__content">
            {caseStudies?.map((resource: any) => (
              <>
                {/*FOR TESTING**/
                /* <div>{JSON.stringify(resource)}</div> */}
                <FileDownloadComponent
                  fileData={resource}
                  handleDownload={handleDownload}
                />
              </>
            ))}
          </div>
          {/* <div className="caseStudyResources__case-study">
            <h1>Case Studies on Digital Reality</h1>
            <Row>
              <Col>
                <SalesPlayCard />
              </Col>
              <Col>
                <SalesPlayCard />
              </Col>
              <Col>
                <SalesPlayCard />
              </Col>
            </Row>
          </div> */}
        </Container>
      </div>
    </>
  );
};

export default CaseStudyDetails;
