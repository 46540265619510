import Showdown from 'showdown';

export const converter = new Showdown.Converter({
  tables: true,
  tasklists: true,
  strikethrough: true,
  omitExtraWLInCodeBlocks: true,
  parseImgDimensions: true,
  simplifiedAutoLink: true,
  literalMidWordUnderscores: true,
  ghCodeBlocks: true,
  smoothLivePreview: true,
  ghCompatibleHeaderId: true,
  encodeEmails: true,
  ellipsis: true,
});
