import { Dispatch } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../action-types';

export const faqAction = (data: object) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.LOAD_FAQ_DETAILS,
      payload: data,
    });
  };
};
