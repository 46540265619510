import { marked } from 'marked';

const convertMarkdownToText = (markdown: string) => {
  if (markdown) {
    markdown = markdown.replace(/(\r\n|\n|\r)/gm, '\n ');
    markdown = markdown.replace(/(<BR>)/gm, '<BR> \n ');
    const html = marked(markdown, { gfm: true });
    return html;
  } else {
    console.log('No Content Found!');
  }
};

export default convertMarkdownToText;
