import axios from 'axios';
import { Dispatch } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../action-types';
import { searchResults } from '../../models/play-details';

export const loadSearchResults = (query: any) => {
  const resetData = {
    searchResultsCategories: [],
    searchResultsResponse: [],
    downloadFilesData: [],
  };
  return async (dispatch: Dispatch<Action>) => {
    try {
      const searchData: any = await axios.get<searchResults>(
        `./mock-data/search-results-data.txt`
      );

      const queriedData = searchData.data[query];

      dispatch({
        type: ActionType.LOAD_SEARCH_RESULTS_SUCCESS,
        payload: queriedData || resetData,
      });
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: ActionType.LOAD_SEARCH_RESULTS_ERROR,
          payload: err.message,
        });
      }
    }
  };
};
