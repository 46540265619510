import { useEffect, useState } from 'react';
import { UserEngagementAPI } from '@deloitte/blueprint-sdk-engagement';
import { UserAPI } from '@deloitte/blueprint-sdk-user';
import { UserAssetAPI } from '@deloitte/blueprint-sdk-asset';
import { useMsal } from '@azure/msal-react';
import config from '../lib/config';
import { User } from '../models/play-details';
import { useActions } from '../state/hooks/useActions';

const AuthService: any = () => {
  const { accounts } = useMsal();
  const [isErrorState, setErrorState] = useState<boolean>(false);
  const [userApi, setUserApi] = useState<UserAPI | undefined>(undefined);
  const [userAssetApi, setUserAssetApi] = useState<UserAssetAPI | undefined>(
    undefined
  );
  const [userEngagementApi, setUserEngagementApi] = useState<
    UserEngagementAPI | undefined
  >(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);
  const { setUserAuth } = useActions();
  const handleLogin = async (accounts: any) => {
    try {
      if (accounts[0]?.idTokenClaims) {
        const userApi = new UserAPI(config);
        const authedUser = await userApi.assume(
          'DeloitteAuth',
          'email',
          accounts[0].username,
          { status: 'active' }
        );
        // const authedUser = await userApi.seed();
        setUserApi(userApi);

        const userAssetApi = new UserAssetAPI(config, authedUser.body);
        setUserAssetApi(userAssetApi);
        const userEngagementApi = new UserEngagementAPI(
          config,
          authedUser.body
        );
        setUserEngagementApi(userEngagementApi);

        const userObj: User = {
          firstName: accounts[0].name?.split(', ')[1],
          lastName: accounts[0].name?.split(', ')[0],
          email: accounts[0].username,
          token: authedUser.body,
        };

        document.cookie = `Auth=${authedUser.body}; path: '/'`;
        setUser(userObj);
      }
    } catch (error: unknown) {
      setErrorState(true);
      console.error(error);
    }
  };

  useEffect(() => {
    handleLogin(accounts);
  }, [accounts]);

  setUserAuth({
    userApi,
    userAssetApi,
    userEngagementApi,
    user,
  });

  return null;
};

export default AuthService;
