import { useEffect, useRef } from 'react';
import { useActions } from '../../state/hooks/useActions';
import './ModalComponent.scss';
import closeModal from '../../assets/icons/crossLarge.svg';
import { Container } from 'react-bootstrap';

const ModalComponent = (props: any) => {
  const { toggleModal } = useActions();
  const modal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
      toggleModal(false);
    };
  }, []);

  const handleModal = () => {
    toggleModal(false);
  };

  return (
    <div className="sp-modal">
      <div onClick={handleModal} className="sp-modal__closeBtn">
        <img src={closeModal} alt="close modal" />
      </div>
      <Container>
        <div id="modalOverlay" ref={modal} className="sp-modal__overlay">
          {props.children}
        </div>
      </Container>
    </div>
  );
};

export default ModalComponent;
