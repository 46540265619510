export enum ListTypes {
  Bullet = 'bullet',
  Numbered = 'numbered',
  None = 'none',
  Table = 'Table',
}
export enum MediaItemsType {
  Carousel = 'carousel',
  Video = 'video',
  SolutionsCard = 'solution-cards',
  SolutionLinks = 'solution-links',
  Downloads = 'Downloads',
  Link = 'link',
  PageLinks = 'pageLinks',
}

export interface ListItemSchema {
  type: 'bullet' | 'numbered' | 'none' | 'Table';
  title?: string;
  points: string[];
}

export interface MediaItemsDetailsSchema {
  src?: string;
  title?: string;
  description?: string;
}

export interface User {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string;
  token: string;
}

export interface MediaItems {
  type:
    | 'carousel'
    | 'video'
    | 'solution-cards'
    | 'solution-links'
    | 'downloads'
    | 'pageLinks';
  details: MediaItemsDetailsSchema[];
}

interface ContentSectionSchema {
  description?: string;
  list?: ListItemSchema;
  media?: MediaItems;
}

interface IndividualSectionSchema {
  title: string;
  id: string;
  content: ContentSectionSchema[];
}

export interface PlayDetails {
  playDetailsCategories: [];
}

export interface PlayDetailsx {
  title: string;
  description: string;
  sections: IndividualSectionSchema[];
}

export interface playDetailsCategory {
  title: string;
  description: string;
  sections: IndividualSectionSchema[];
  phases: IndividualSectionSchema[];
  palette: any;
}

export interface searchResults {
  searchResultsCategories: [];
  searchResultsResponse: [];
  downloadFilesData: [];
}
