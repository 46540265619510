import React, { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import sunBurstChart from './sunburst-d3';
import { useActions } from '../../state/hooks/useActions';
import { useTypedSelector } from '../../state/hooks/useTypedSelector';
import './Sunburst.scss';
import ChartData from '../../models/chart-data';
import '../scrollspy-nav/scrollspy-nav.scss';
import BreadcrumbComponent from '../breadcrumb/breadcrumb';
import { getTheme, spStringConstants } from '../../Utilities/sp-strings';
import { Col, Row } from 'react-bootstrap';
import DownloadsSection from '../media-items/downloads/downloads';
import { converter } from '../../Utilities/showDown';
import useDPApi from '../../lib/api';
import { ThemeProvider } from 'styled-components';

interface SunburstProps {
  masterChartData: ChartData | null;
  chartData?: ChartData | null;
  showContent: boolean;
  chartSelection?: any;
  stopAnimation?: boolean;
}

const Sunburst: React.FC<SunburstProps> = ({
  masterChartData,
  chartData,
  showContent,
  chartSelection,
  stopAnimation,
}: SunburstProps) => {
  let notInitialized = true;
  let url: any = null;
  const { setChartSelection } = useActions();
  const svgRef = React.createRef<SVGSVGElement>();
  const contentSectionRef = React.createRef<HTMLDivElement>();
  const buttonRef = React.createRef<HTMLButtonElement>();
  const contentWrap = React.createRef<HTMLDivElement>();
  const sunburstContent = React.createRef<HTMLDivElement>();
  const videoSectionRef = React.createRef<HTMLDivElement>();
  const svgContanerRef = React.createRef<HTMLDivElement>();
  const sunburstButton = document.getElementById('viewMore');
  const navigate = useNavigate();
  const sunBurstPlayContent = useTypedSelector((state) => state?.playDetails);
  const sunBurstPhaseContent = useTypedSelector((state) => state?.phaseDetails);
  const { loadPhaseData } = useDPApi();
  const sunburstSelectionData = useTypedSelector(
    (state) => state.sunbursSelection.selection
  );

  const goToDetails = () => {
    if (buttonRef.current?.getAttribute('data-navigate')) {
      navigate(url);
    }
  };

  const currentTheme: any = getTheme.find((category: any) => {
    return category.name === sunburstSelectionData?.data?.name;
  });

  const theme = {
    background: currentTheme?.palette?.shade2,
    color: currentTheme?.palette?.hue,
    mediaItemsBackdrop: currentTheme?.palette?.shade,
    highlight: currentTheme?.palette?.primary,
  };
  const customClickHandler = (event: any) => {
    let redirectionURL = '';
    const type = event.data.type;
    if (type == 'phase') {
      redirectionURL = `/${event.parent.data.name}/phases?id=${event.data.id}`;
    } else {
      redirectionURL = `/${event.data.name}`;
    }
    navigate(redirectionURL);
  };
  useEffect(() => {
    notInitialized = false;
    if (svgRef && svgRef.current) {
      svgRef.current.innerHTML = '';
    }
    sunBurstChart({
      chartData: masterChartData,
      svgRef,
      contentSectionRef,
      buttonRef,
      contentWrap,
      videoSectionRef,
      setChartSelection,
      navigate,
      showContent,
      chartSelection,
      svgContanerRef,
      customClickHandler: stopAnimation ? customClickHandler : null,
    });
    return () => {
      setChartSelection(null);
    };
  }, []);

  const sunBurstSelectedContent =
    sunburstSelectionData?.data?.type == 'play'
      ? sunBurstPlayContent?.data?.find((content: any) => {
          return content?.title == sunburstSelectionData?.data?.name;
        })
      : sunBurstPhaseContent?.data?.phases?.phaseTypes?.find((content: any) => {
          return content?.tabTitle == sunburstSelectionData?.data?.name;
        });

  useLayoutEffect(() => {
    if (sunburstContent.current) {
      const elem = sunburstContent.current;
      elem.classList.add('fade-in-animation');
      setTimeout(() => {
        elem.classList.remove('fade-in-animation');
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (sunburstContent?.current) {
      sunburstContent?.current.classList.add('fade-in-animation');
      setTimeout(() => {
        if (sunburstContent?.current) {
          sunburstContent?.current.classList.remove('fade-in-animation');
        }
      }, 1000);
    }
  }, [sunBurstSelectedContent, sunburstSelectionData?.data?.name]);

  useEffect(() => {
    if (sunburstSelectionData?.parent?.data?.type == 'play') {
      loadPhaseData(sunburstSelectionData?.parent?.data?.name);
    }
  }, [loadPhaseData, sunburstSelectionData?.parent?.data?.name]);

  url =
    sunburstSelectionData?.data?.type == 'play'
      ? `/${sunburstSelectionData?.data?.name}`
      : `/${sunburstSelectionData?.parent?.data?.name}/phases${
          sunburstSelectionData?.data?.index == 0 ||
          !sunburstSelectionData?.data?.index
            ? ''
            : `?id=${sunburstSelectionData?.data?.id}`
        }`;
  const breadcrumbLevel = [
    {
      label: sunburstSelectionData?.parent?.parent?.parent?.parent?.data?.name,
    },
    { label: sunburstSelectionData?.parent?.parent?.parent?.data?.name },
    { label: sunburstSelectionData?.parent?.parent?.data?.name },
    { label: sunburstSelectionData?.parent?.data?.name },
    { label: sunburstSelectionData?.data?.name },
  ];

  const loader = (
    <div className="sunburst__loader">
      <div className="sunburst__loader--box"></div>
      <div className="sunburst__loader--box"></div>
      <div className="sunburst__loader--box"></div>
      <div className="sunburst__loader--box"></div>
      <div className="sunburst__loader--box"></div>
    </div>
  );

  return (
    <>
      {!sunburstSelectionData?.data?.name ? (
        <p>Explore Delivery Plays</p>
      ) : (
        <BreadcrumbComponent routes={breadcrumbLevel} />
      )}
      <Row className="sunburst__wrapper">
        <Col className="dp-sunburst">
          <div className="sunburst__innner">
            <div className="sunburst__svg-continer">
              {/* {sunburstSelectionData?.depth !== 0 && comingFrom !=='home' && <BreadcrumbComponent routes={null}></BreadcrumbComponent>} */}
              <svg id="partitionSVG" ref={svgRef} width="600" height="700" />
            </div>
          </div>
          {!showContent && !stopAnimation && (
            <div className="sunburst__btn-wrapper">
              <button
                id="viewMore"
                ref={buttonRef}
                onClick={goToDetails}
                className="sunburst__view-more"
                style={{
                  backgroundColor: sunburstButton?.textContent
                    ? sunburstSelectionData?.data?.color
                    : '#FFFF',
                }}
              >
                {spStringConstants.initialButtonLabel}
              </button>
            </div>
          )}
        </Col>
        {showContent && (
          <ThemeProvider theme={theme}>
            <Col ref={sunburstContent} className="dp-sunburst-content">
              {!sunburstSelectionData?.data?.type && (
                <div className="dp-sunburst-content">
                  <p className="dp-sunburst-content--initialHeader sub-heading">
                    Explore the delivery plays by clicking on the sunburst
                  </p>
                </div>
              )}
              <h1 className="dp-sunburst-content--primaryHeader">
                {sunBurstSelectedContent?.title}
              </h1>
              <p className="sub-heading">
                {sunBurstSelectedContent?.description?.md}
              </p>
              <div className="dp-sunburst-content__summary">
                <h2 className="dp-sunburst-content--secondaryHeader">
                  {sunBurstSelectedContent?.summary?.title}
                </h2>
                <div
                  className="dp-sunburst-content--description"
                  dangerouslySetInnerHTML={{
                    __html: converter?.makeHtml(
                      sunBurstSelectedContent?.summary?.description?.md
                    ),
                  }}
                ></div>
              </div>
              <div className="dp-sunburst-content__downloads">
                <h2 className="dp-sunburst-content--secondaryHeader">
                  {sunBurstSelectedContent?.quickDownloads?.title}
                </h2>
                {sunburstSelectionData?.data?.type === 'play' && (
                  <DownloadsSection
                    downloads={
                      sunBurstSelectedContent?.quickDownloads
                        ?.quickDownloadsItems
                    }
                  />
                )}
              </div>
              {sunburstSelectionData?.data?.type === 'phase' && (
                <>
                  {!sunBurstSelectedContent && loader}
                  <h1 className="dp-sunburst-content--primaryHeader">
                    {sunBurstSelectedContent?.tabTitle}
                  </h1>
                  <h2 className="dp-sunburst-content--secondaryHeader">
                    {sunBurstSelectedContent?.tabContent[0]?.contentHeader}
                  </h2>
                  <div
                    className="dp-sunburst-content--description"
                    dangerouslySetInnerHTML={{
                      __html: converter?.makeHtml(
                        sunBurstSelectedContent?.tabContent[0]?.contentBody?.md
                      ),
                    }}
                  ></div>
                </>
              )}

              <button
                id="viewMore"
                ref={buttonRef}
                onClick={goToDetails}
                className={`sunburst__view-more ${
                  !sunBurstSelectedContent ? 'hideContent' : ''
                }`}
                style={{
                  backgroundColor: sunburstButton?.textContent
                    ? sunburstSelectionData?.data?.color
                    : '#FFFF',
                }}
              >
                {spStringConstants.initialButtonLabel}
              </button>
            </Col>
          </ThemeProvider>
        )}
      </Row>
    </>
  );
};
export default Sunburst;
