import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const SelectionDetailsStyle = styled.div`
  color: white;
  min-height: 100vh;
  background-color: ${(props) => props.theme.background};
`;

export const VisitMore = styled.a`
  color: inherit;
  cursor: pointer;
  display: inline-block;
  &:hover {
    color: ${(props) => props.theme.color};
  }
`;
export const TitleLink = styled(NavLink)`
  color: inherit;
  &:hover {
    color: ${(props) => props.theme.color};
  }
`;

export const NoDataStyled = styled.div`
  color: ${(props) => props.theme.highlight};
`;
export const DpSections = styled.div`
  .section__phasesInPlay {
    a {
      font-size: 1.25rem;
    }
  }

  .section__successStory {
    a {
      border-radius: 2px;
      border: 1px solid white;
      padding: 12px 12px;
      margin-bottom: 10px;
      display: inline-block;
      width: 33.3%;
      color: inherit;
      text-decoration: none;
    }
  }
  a {
    color: #fff;
    &:hover {
      color: ${(props) => props.theme.color};
    }
  }
`;
