import { Container } from 'react-bootstrap';
import BannerComponent from '../components/Banner/Banner';
import './FAQ.scss';
import AccordionComponent from '../components/Accordion/Accordion';
import { useActions } from '../state/hooks/useActions';
import { spStringConstants } from '../Utilities/sp-strings';
import useDPApi from '../lib/api';
import { useEffect, useState } from 'react';
import { useTypedSelector } from '../state/hooks/useTypedSelector';
import Pagination from '../components/Pagination/Pagination';
import { converter } from '../Utilities/showDown';

const FAQPage = () => {
  const { setTheme } = useActions();
  const { loadFAQPage } = useDPApi();
  const faqData = useTypedSelector((state) => state.setFAQContent);
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    loadFAQPage();
  }, []);

  const handleIncrement = () => {
    const newPage = currPage + 1;
    setCurrPage(newPage);
    loadFAQPage(newPage);
  };

  const handleDecrement = () => {
    const newPage = currPage - 1;
    setCurrPage(newPage);
    loadFAQPage(newPage);
  };

  setTheme({
    background: spStringConstants?.initialFooterTheme,
    highlight: '#ffff',
  });
  return (
    <>
      <div className="faqPage">
        <BannerComponent title="Frequently Asked Questions" />
        <div className="faqPage__content">
          {!faqData?.hits && (
            <div className="loader">
              <div className="loader-2 center">
                <span></span>
              </div>
            </div>
          )}
          <Container>
            <div className="faqPage__actionPanel d-flex justify-content-between">
              <label className="regular">All Questions</label>
              <Pagination
                handleIncrement={handleIncrement}
                handleDecrement={handleDecrement}
                totalPages={faqData?.totalPages}
                currPage={faqData?.totalHits == 0 ? 0 : currPage}
              />
            </div>
            <div className="faqPage__list">
              {faqData?.hits?.map((faqItem: any) => (
                <div className="faqPage__list__accordion">
                  <AccordionComponent title={faqItem?.title}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: converter?.makeHtml(faqItem?.description?.md),
                      }}
                    ></div>
                  </AccordionComponent>
                </div>
              ))}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default FAQPage;
