export const spStringConstants = {
  spHeaderData: {
    title: 'Case Studies & Resources',
    // items: [
    //   ['Recently Added Resources', 'Featured Case Study'],

    //   ['Popular Resources', 'All Case Studies'],
    // ],
    items: [
      [{ text: 'Resources', urlFilter: 'Resources' }],
      [{ text: 'Case Studies', urlFilter: 'Case Studies' }],
      [{ text: 'All Documents', urlFilter: '' }],
    ],
  },
  initialButtonLabel: 'Learn More',
  initialBreadCrumbLabel: 'Delivery Plays',
  initialFooterTheme: '#263519',
  neutralFooterTheme: '#333333',
};

export const getTheme = [
  {
    name: 'Benchmarks & Assessments',
    type: 'play',
    color: '#DA93C8',
    palette: {
      shade: '#581948',
      hue: '#C90D99',
      primary: '#DA93C8',
      shade2: '#412A3B',
    },
  },
  {
    name: 'Experience Vision',
    type: 'play',
    palette: {
      shade: '#4D3206',
      hue: '#F79A05',
      primary: '#EFCB92',
      shade2: '#332714',
    },
  },
  {
    name: 'Enterprise UX',
    type: 'play',
    color: '#D7F4A9',
    palette: {
      shade: '#253B01',
      hue: '#8DE500',
      primary: '#D7F4A9',
      shade2: '#263213',
    },
  },
  {
    name: 'Product Incubation',
    type: 'play',
    color: '#9DDBDA',
    palette: {
      shade: '#004948',
      hue: '#00E8E4',
      primary: '#9DDBDA',
      shade2: '#042F2E',
    },
  },
  {
    name: 'Experiences & Products @ Scale',
    type: 'play',
    color: '#B0F5B4',
    palette: {
      shade: '#014805',
      hue: '#04CB10',
      primary: '#B0F5B4',
      shade2: '#042D07',
    },
  },
  {
    name: 'Composable Experiences',
    type: 'play',
    color: '#F4A5A4',
    palette: {
      shade: '#45100F',
      hue: '#F97573',
      primary: '#F4A5A4',
      shade2: '#300101',
    },
  },
  {
    name: 'Future Plays',
    type: 'play',
    color: '#A38FC8',
    palette: {
      shade: '#332945',
      hue: '#6016E9',
      primary: '#A38FC8',
      shade2: '#1B1525',
    },
  },
  {
    name: 'Engineering',
    type: 'play',
    color: '#95B1E8',
    palette: {
      shade: '#0C224D',
      hue: '#0052F4',
      primary: '#95B1E8',
      shade2: '#15223A',
    },
  },
];
