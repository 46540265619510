import { ActionType } from '../action-types';
import { Action } from '../actions';

const initialState: any = true;

const loaderReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_LOADER:
      return action.payload;
    default:
      return state;
  }
};

export default loaderReducer;
