import styled from 'styled-components';

export const StyledSkeletonWrapper = styled.div`
  background-color: ${(props) => props.theme.background};
  min-height: 100vh;
  width: 100%;
  z-index: 99;
  position: absolute;
`;

export const StyledSkeleton = styled.div.attrs((props: any) => ({
  width: props.width,
  height: props.height,
}))`
  width: ${(props) => props.width};
  min-height: ${(props) => props.height};
  background-color: ${(props) => props.theme.mediaItemsBackdrop};
  margin-bottom: 20px;
  animation: fade 1.8s infinite;
  @keyframes fade {
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
`;
