import { useCallback } from 'react';
import { useActions } from '../state/hooks/useActions';
import searchClient from './searchApi';

const useDPApi = () => {
  const { loadPlayDetails } = useActions();
  const { loadPhaseDetails } = useActions();
  const { loadAction } = useActions();
  const { landingAction } = useActions();
  const { loadSearchAction } = useActions();
  const { loadCaseResources } = useActions();
  const { loadChartData } = useActions();
  const { faqAction } = useActions();
  // const testGenerator = function* () {
  //   const resourceData: string = yield 1;

  //   const b: string = yield 2;

  //   return '';
  // };

  // const testGen = testGenerator();
  // const testGenInit = testGen.next();

  const genSunBurstChart = async (playData: any) => {
    const masterChart: any = {
      name: 'Explore Delivery Plays',
      color: '#FFFF',
      children: [],
    };
    for (const play of playData) {
      const response = searchClient.index('content-phases-in-plays');
      const data = await response.search(play.title, {
        attributesToRetrieve: [
          'title',
          'phases.phaseTypes.tabTitle',
          'phases.phaseTypes.tablInternalId',
          'phases.phaseTypes.tabSunBurst.color',
        ],
      });
      const phasesData = data?.hits[0];
      const phaseChildren = [];
      const phaseTypes: any = phasesData?.phases?.phaseTypes;
      if (phaseTypes) {
        const chartCircleVal = Math.round(100 / phaseTypes?.length);
        for (const phase of phaseTypes) {
          phaseChildren.push({
            id: phase?.tablInternalId,
            name: phase?.tabTitle,
            color: phase?.tabSunBurst?.color,
            type: 'phase',
            value: chartCircleVal,
          });
        }
      }

      const playObj: any = {
        name: play.title,
        type: 'play',
        color: play?.sunburst?.color,
        shades: {
          shade1: play?.sunburst?.shades?.shade1,
          shade2: play?.sunburst?.shades?.shade2,
          hue: play?.sunburst?.shades?.hue,
        },
        children: phaseChildren,
      };
      masterChart.children.push(playObj);
    }
    loadChartData(masterChart);
  };
  const loadPlayData = useCallback(async () => {
    const response = searchClient.index('content-delivery-plays');
    const data = await response.search('');
    await genSunBurstChart(data?.hits);
    loadPlayDetails(data?.hits);
  }, []);

  const loadPhaseData = useCallback(async (query: any) => {
    const response = searchClient.index('content-phases-in-plays');
    const data = await response.search(query);
    loadAction(false);
    loadPhaseDetails(data?.hits[0]);
  }, []);

  const loadLandingPage = useCallback(async () => {
    const response = searchClient.index('content-landingPage');
    const data = await response.search('landingPage');
    if (data?.hits[0]) {
      landingAction(data?.hits[0]);
    }
  }, []);

  const loadFAQPage = useCallback(async (page = 1, hitsPerPage = 10) => {
    const response = searchClient.index('content-faqpage');
    const data = await response.search('', {
      hitsPerPage,
      page,
    });
    if (data?.hits[0]) {
      faqAction(data);
    }
  }, []);

  const loadResources = useCallback(
    async (filters?: any, page = 1, hitsPerPage = 10) => {
      const response = searchClient.index('content-resourcesandcasestudies');
      let data: any;
      if (filters) {
        let searchparam = '';
        let contentSearchParam: any = '';
        if (filters?.topics && filters?.topics?.length > 0) {
          filters.topics.forEach((topic: any, idx: number) => {
            if (idx == 0) {
              searchparam = `topic = "${topic}"`;
            } else {
              searchparam = `${searchparam} OR topic = "${topic}"`;
            }
          });
        }
        if (filters?.content && filters?.content?.length > 0) {
          filters.content.forEach((content: any, idx: number) => {
            if (idx == 0) {
              contentSearchParam = `contentType = "${content}"`;
            } else {
              contentSearchParam = `${contentSearchParam} OR contentType = "${content}"`;
            }
          });
        }
        if (searchparam && contentSearchParam) {
          searchparam = `${searchparam} AND ${contentSearchParam}`;
        } else if (!searchparam && contentSearchParam) {
          searchparam = contentSearchParam;
        } else if (searchparam && !contentSearchParam) {
          searchparam = searchparam;
        }
        data = await response.search('', {
          filter: searchparam,
          hitsPerPage,
          page,
          sort: ['createdAt:desc'],
        });
      } else {
        data = await response.search('', {
          hitsPerPage,
          page,
          sort: ['createdAt:desc'],
        });
      }

      const resources = data?.hits;
      if (data?.hits[0]) {
        loadCaseResources({
          resources,
          totalPages: data?.totalPages,
        });
      } else {
        loadCaseResources({
          resources: [],
          totalPages: 0,
        });
      }
    },
    []
  );

  const loadSearchQuery = useCallback(
    async (query: any, page = 1, hitsPerPage = 5) => {
      const deliveryPlayResponse = searchClient.index('content-delivery-plays');
      const caseStudiesResourcesResponse = searchClient.index(
        'content-resourcesandcasestudies'
      );
      const caseStudiesResourcesData = await caseStudiesResourcesResponse.search(
        query,
        {
          attributesToHighlight: ['title', 'topic', 'description'],
          attributesToCrop: ['title', 'topic', 'description'],
          hitsPerPage,
          page,
        }
      );
      const phasesResponse = searchClient.index('content-phases-in-plays');
      const deliveryData = await deliveryPlayResponse.search(query, {
        attributesToHighlight: [
          'quickDownloads',
          'summary',
          'problemStatement',
          'keyDifferentiators',
          'ourApproach',
          'phasesInPlay',
          'successStories',
        ],
        attributesToCrop: ['summary', 'keyDifferentiators'],
        cropLength: 20,
        hitsPerPage,
        page,
      });
      const phaseData = await phasesResponse.search(query, {
        attributesToHighlight: ['description', 'phases.phaseTypes'],
        hitsPerPage,
        page,
      });
      const compiledData = {
        hits: [
          ...deliveryData?.hits,
          ...phaseData?.hits,
          ...caseStudiesResourcesData?.hits,
        ],
        totalPages: deliveryData?.totalPages,
      };
      if (compiledData?.hits.length >= 0) {
        loadSearchAction(compiledData);
      }
    },
    []
  );
  return {
    loadPlayData,
    loadPhaseData,
    loadLandingPage,
    loadSearchQuery,
    loadResources,
    loadFAQPage,
  };
};

export default useDPApi;
