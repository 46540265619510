import { combineReducers } from 'redux';
import authReducer from './authReducer';
import chartDataReducer from './chartDataReducer';
import fileDownloadReducer from './fileDownloadReducer';
import landingPageReducer from './landingPageReducer';
import resourcePageReducer from './resourcePageReducer';
import modalReducer from './modalReducer';
import phaseDetailsReducer from './phaseDetailsReducer';
import playDetailsReducer from './playDetailsReducer';
import searchQueryReducer from './searchQueryReducer';
import searchResultsReducer from './searchResultsReducer';
import sunburstStateReducer from './sunburstStateReducer';
import themeReducer from './themeReducer';
import faqPageReducer from './faqPageReducer';
import loaderReducer from './loaderReducer';

const reducers = combineReducers({
  chartData: chartDataReducer,
  playDetails: playDetailsReducer,
  phaseDetails: phaseDetailsReducer,
  sunbursSelection: sunburstStateReducer,
  searchResults: searchResultsReducer,
  fileDownloadSelection: fileDownloadReducer,
  toggleModal: modalReducer,
  getThemeColor: themeReducer,
  setUserAuth: authReducer,
  setLandingPageContent: landingPageReducer,
  setSearchQuery: searchQueryReducer,
  setResources: resourcePageReducer,
  setFAQContent: faqPageReducer,
  setLoading: loaderReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
