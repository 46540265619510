import { Accordion } from 'react-bootstrap';
import './Accordion.scss';

const AccordionComponent = (props: any) => {
  return (
    <Accordion className="dp-accordion">
      <Accordion.Item className="dp-accordion__item" eventKey="0">
        <Accordion.Header className="dp-accordion__header">
          <p>{props.title}</p>
        </Accordion.Header>
        <Accordion.Body className="dp-accordion__body">
          {props.children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionComponent;
