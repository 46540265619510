import MeiliSearch from 'meilisearch';

const searchClient = new MeiliSearch({
  host:
    process.env.REACT_APP_SEARCH_URL ||
    'https://search.staging.salesportal.sandbox.ddapps.xyz',
  apiKey:
    process.env.REACT_APP_SEARCH_API_KEY ||
    'a5721ef119bd5cccbc3681e680aaa6ec67056de2c5b7d1a64b9daf828fad6d03',
});

export default searchClient;
