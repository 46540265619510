export const dpStyledConstants = {
  LeftBoxSizings: [
    { width: '233px', height: '232px' },
    { width: '210px', height: '24px' },
    { width: '184px', height: '24px' },
    { width: '160px', height: '24px' },
    { width: '121px', height: '24px' },
    { width: '226px', height: '24px' },
    { width: '145px', height: '24px' },
    { width: '101px', height: '24px' },
    { width: '72px', height: '24px' },
    { width: '91px', height: '24px' },
    { width: '210px', height: '24px' },
    { width: '130px', height: '24px' },
    { width: '226px', height: '24px' },
  ],

  RightBoxSizings: [
    { width: '380px', height: '66px' },
    { width: '745px', height: '24px' },
    { width: '288px', height: '34px' },
    { width: '745px', height: '24px' },
    { width: '745px', height: '24px' },
    { width: '350px', height: '24px' },
  ],

  OtherBoxSizings: { width: '777px', height: '156px' },
};
