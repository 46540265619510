import { Col, Container, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import background from '../../assets/media/green-bg.jpg';
import triangles1 from '../../assets/media/Hero-section-1.svg';
import triangles2 from '../../assets/media/Hero-section-2.svg';
import triangles3 from '../../assets/media/Hero-section-3.svg';
import triangles4 from '../../assets/media/Hero-section-4.svg';

import './Hero.scss';
import HeroImage from '../../assets/media/hero.png';

const Hero = ({ heroData }: any) => {
  const cardSections = heroData?.hero?.challenges?.map(
    (section: any, index: number) => (
      <Col md={6}>
        <Row className="sp-hero__card-section d-flex align-items-center">
          <Col
            className="d-md-flex align-items-center justify-content-center"
            lg={6}
          >
            {section?.image?.src === 'triangles1' && (
              <img src={triangles1} alt={section?.image?.title} />
            )}
            {section?.image?.src === 'triangles2' && (
              <img src={triangles2} alt={section?.image?.title} />
            )}
            {section?.image?.src === 'triangles3' && (
              <img src={triangles3} alt={section?.image?.title} />
            )}
            {section?.image?.src === 'triangles4' && (
              <img src={triangles4} alt={section?.image?.title} />
            )}
          </Col>
          <Col className="p-0">
            <div className="sp-hero__card-section--description">
              <h2>{section?.title}</h2>
              <label className="highlight">{section?.subTitle}</label>
              <ReactMarkdown>{section?.description}</ReactMarkdown>
            </div>
          </Col>
        </Row>
      </Col>
    )
  );
  return (
    <>
      <div className="hero__ImageComponent">
        <img src={HeroImage} alt="myImage" />
      </div>
      <div className="hero__video">
        <Container className="hero__container">
          <Row>
            <Col>
              <div className="hero__video--banner">
                {heroData?.bannerContent}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div
        className="sp-hero d-flex align-items-center"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <div className="sp-hero__text animated animatedFadeInUp fadeInUp">
                <p className="title-eyebrow">{heroData?.hero?.titleEyebrow}</p>
                <h1 className="title">{heroData?.hero?.title}</h1>
                <p className="sub-heading">{heroData?.hero?.subHeading}</p>
              </div>
            </Col>
            <Col>
              <div className="sp-hero__card">
                <Row>{cardSections}</Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
};

export default Hero;
