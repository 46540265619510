export enum ActionType {
  LOAD_CHART_DATA = 'load_chart_data',
  LOAD_CHART_DATA_SUCCESS = 'load_chart_data_success',
  LOAD_CHART_DATA_ERROR = 'load_chart_data_error',
  LOAD_PLAY_DETAILS = 'load_play_details',
  LOAD_PLAY_DETAILS_SUCCESS = 'load_play_details_success',
  LOAD_PLAY_DETAILS_ERROR = 'load_play_details_error',
  SUNBURST_SELECTION = 'sunburst_selected',
  LOAD_SEARCH_RESULTS = 'load_search_results',
  LOAD_SEARCH_RESULTS_SUCCESS = 'load_search_results_success',
  LOAD_SEARCH_RESULTS_ERROR = 'load_search_results_error',
  FILE_SELECTION = 'file_selected',
  TOGGLE_MODAL = 'toggle_modal',
  SET_THEME = 'set_theme',
  SET_USER_AUTH = 'set_user_auth',
  LOAD_PHASE_DETAILS = 'load_phase_details',
  LOAD_LANDING_DETAILS = 'load_landing_details',
  LOAD_PHASE_DETAILS_SUCCESS = 'load_phase_details_success',
  LOAD_PHASE_DETAILS_ERROR = 'load_phase_details_error',
  LOAD_SEARCH_QUERY = 'load_search_query',
  LOAD_RESOURCES = 'load_resources',
  LOAD_RESOURCES_SUCCESS = 'load_resources_success',
  LOAD_RESOURCES_ERROR = 'load_resources_error',
  LOAD_FAQ_DETAILS = 'load_faq_details',
  SET_LOADER = 'set_loader',
}
