import Back from '../../assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';

const BackNavigation = () => {
  const navigate = useNavigate();

  return (
    <div
      className="d-flex align-self-center align-items-start"
      onClick={() => navigate('/')}
    >
      <img src={Back} alt="back-button" />
      <p className="sp-sel-details__back">Back</p>
    </div>
  );
};

export default BackNavigation;
