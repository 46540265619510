import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { styles, stylesheet } from './PDFDownload.styles';
import DeloitteLogo from '../assets/media/D-dot.png';
import HeaderBg from '../assets/media/green-bg.jpg';
import SunBurst from '../assets/media/sunburst.png';
import convertMarkdownToText from './marked';
import { converter } from './showDown';
import { Html } from 'react-pdf-html';
import { marked } from 'marked';

const PDFDownloader = (props: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.headerText}>The Plays</Text>
          <Image style={styles.headerLogo} src={DeloitteLogo} />
          <Image style={styles.headerBg} src={HeaderBg} />
        </View>
        <View style={styles.titleWrapper}>
          <Image style={styles.sunBurst} src={SunBurst} />
          <Text style={styles.playTitle}>{props?.data?.title}</Text>
        </View>
        {Object?.values(props?.data)?.map(
          (section: any) =>
            section?.title &&
            !section?.mediaType && (
              <>
                <View style={styles.section}>
                  <>
                    <Text style={styles.sectionTitle}>{section?.title}</Text>
                    <Html stylesheet={stylesheet}>{`${convertMarkdownToText(
                      section?.description?.md
                    )}`}</Html>
                  </>
                </View>
              </>
            )
        )}
        <Text
          style={styles.footerPage}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default PDFDownloader;
