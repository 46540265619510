import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../../assets/styles/typography/typography.scss';
import './App.scss';
import { HashRouter, Route, Routes, BrowserRouter } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import LandingPage from '../LandingPage/LandingPage';
import Header from '../header/Header';
import SearchResults from '../search-results/Search-results';
import SelectionDetails from '../selection-details/selection-details';
import PhaseDetailsComponent from '../phase-detail/PhaseDetailsComponent';
import CaseStudyDetails from '../case-study-details/caseStudy-detail';
import Footer from '../footer/footer';
import AuthService from '../../Utilities/authService';
import { msalConfig } from '../../lib/auth';
import SSORedirect from '../SSO-Redirect/SSOlogin';
import { Helmet } from 'react-helmet';
import FAQPage from '../../Pages/FAQ';
import FloatingButton from '../FloatingButton/FloatingButton';

const App: React.FC = () => {
  const authInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={authInstance}>
      <AuthenticatedTemplate>
        <AuthService />
        <BrowserRouter>
          <Header />
          <FloatingButton />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/search/:results" element={<SearchResults />} />
            <Route path="/:page" element={<SelectionDetails />} />
            <Route path="/:page/phases" element={<PhaseDetailsComponent />} />
            <Route path="/caseStudy&resources" element={<CaseStudyDetails />} />
            <Route path="/faq" element={<FAQPage />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SSORedirect />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;
