import { HierarchyNode } from 'd3';
import ChartData from '../../models/chart-data';
import { ActionType } from '../action-types';
import { Action } from '../actions';

interface DataState {
  loading: boolean;
  error: string | null;
  selection: HierarchyNode<any> | null;
}

const initialState: DataState = {
  loading: false,
  error: null,
  selection: null,
};

const sunburstStateReducer = (
  state: DataState = initialState,
  action: Action
): DataState => {
  switch (action.type) {
    case ActionType.SUNBURST_SELECTION:
      return { loading: true, error: null, selection: action.payload };

    default:
      return state;
  }
};

export default sunburstStateReducer;
